#BravectoExperience {
  width: 100%;
  height: 100%;

}

#BravectoExperience .bg-lp {
  position: relative;
  display: flex;
}

/* #BravectoExperience .first-bg::after {
  position: absolute;
  content: "";
  background-image: url('../../images/BravectoExperience/arrrow-down.svg');
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  width: 12px;
  height: 66px;
  left: 0;
  right: 0;
  bottom: 4.5rem;
  margin: 0 auto;
  transform: translate(50%, 50%);
} */

#BravectoExperience .bg-lp .banner-desk {
  width: 100%;
}

#BravectoExperience .bg-lp .banner-mob {
  display: none;
}

#BravectoExperience .info-container {
  background-color: #211152;
  display: flex;
  justify-content: center;
  padding: 3.5rem 0;
  width: 100%;
}

#BravectoExperience .info-sub-container {
  display: flex;
  justify-content: center;
  gap: 3.313rem;
  max-width: 1045px;
  width: 100%;
}

#BravectoExperience .info-container .video {
  position: relative;
  border: 3px solid #15A0DB;
  border-radius: 7px;
  max-width: 635px;
  width: 100%;
  max-height: 382px;
  padding: 0.85rem;
}

#BravectoExperience .info-container .video iframe {
  max-width: 635px;
  width: 100%;
}

#BravectoExperience .info-container .video::after {
  position: absolute;
  content: "";
  background-image: url('../../images/BravectoExperience/play.png');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  width: 80px;
  height: 80px;
  top: 40%;
  right: -40px;
}

#BravectoExperience .info-container .info-content {
  max-width: 322px;
  width: 100%;
}

#BravectoExperience .info-container .info-content h1 {
  color: #FFFFFF;
  font-size: 2rem;
}

#BravectoExperience .info-container .info-content span {
  color: #15A0DB;
}

#BravectoExperience .info-container .info-content p {
  color: #FFFFFF;
  font-size: 1.125rem;
}

#BravectoExperience .lojas {
  background-color: #fff;
  padding: 2rem 0 5rem;
  display: flex;
  justify-content: center;
}

#BravectoExperience .lojas .lojas-sub {
  max-width: 1045px;
  width: 100%;
}

#BravectoExperience .lojas .lojas-sub .title h1 {
  color: #ED2764;
  font-size: 2.438rem;
  margin: 0;
}

#BravectoExperience .lojas .lojas-sub .title p {
  font-size: 1.125rem;
  margin-top: 0.5rem;
}

#BravectoExperience .lojas .lojas-sub .filtros-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 5rem;
}

#BravectoExperience .lojas .lojas-sub .filtros-container .todos {
  display: flex;
  align-items: center;
  gap: 0.45rem;
}

#BravectoExperience .lojas .lojas-sub .filtros-container .todos h2 {
  margin: 0;
  font-size: 1.75rem;
}

#BravectoExperience .lojas .lojas-sub .filtros-container .todos p {
  font-size: 0.938rem;
  margin-top: 0.4rem;
}

#BravectoExperience .lojas .lojas-sub .filtros-container .filtros {
  display: flex;
  align-items: center;
  gap: 1rem;
}

#BravectoExperience .lojas .lojas-sub .filtros-container .filtros .filter-mob {
  display: none;
}

#BravectoExperience .lojas .lojas-sub .filtros-container .filtros .selects {
  display: flex;
  align-items: center;
  gap: 1rem;
}

.selects #container__select .css-2b097c-container .css-1hwfws3,
#container__select .css-14jk2my-container .css-1hwfws3 {
  left: 0;
  bottom: 0;
}

.selects #container__select .css-2b097c-container .css-yk16xz-control .css-g1d714-ValueContainer .css-1uccc91-singleValue {
  top: 50%;
}

.selects #container__select {
  margin-bottom: 0;
}

.selects #container__select:nth-child(1) {
  width: 89px;
}

.selects #container__select:nth-child(2) {
  width: 154px;
}

.selects #container__select:nth-child(3) {
  width: 192px;
}

.selects #container__select:nth-child(4) {
  width: 134px;
}

.selects #container__select svg {
  top: -0.1em;
  width: 1rem;
  height: 1rem;
  color: #ED2764;
}

.input-date .react-datepicker__input-container {
  position: relative !important;
}

.input-date .react-datepicker__input-container input {
  height: 2.45rem;
  border-radius: 1.4rem;
  border: 1px solid #dfe2e8;
  text-indent: 0.5rem;
  cursor: pointer;
  width: 180px;
}

.input-date-mobile .react-datepicker__input-container input {
  width: 140px;
}

/* .input-date .react-datepicker__input-container::after {
  position: absolute;
  content: "";
  background-image: url('../../images/arrow-down.svg');
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  width: 16px;
  height: 16px;
  right: 0.8rem;
  top: 0.55rem;
} */

#BravectoExperience .card-container {
  background-color: #F2F2F2;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
}

#BravectoExperience .card-container .card-sub-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 1.438rem;
  margin-top: -2.675rem;
  max-width: 1045px;
  width: 100%;
  list-style-type: none;
}

#BravectoExperience .card-container .card-sub-container .card {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: #58317E;
  border-radius: 5px;
  color: #fff;
  width: 332px;
  max-height: 395px;
  height: 100%;
  box-shadow: 5px 6px 6px rgba(0, 0, 0, 0.302);
  margin-bottom: 5rem;
}

#BravectoExperience .card-container .card-sub-container .card-content {
  padding: 1.75rem 1.219rem 0;
}

#BravectoExperience .card-container .card-sub-container .card-content h3,
p {
  margin: 0;
}

#BravectoExperience .card-container .card-sub-container .card-content h3 {
  font-size: 1.563rem;
}

#BravectoExperience .card-container .card-sub-container .card-content .infos {
  display: flex;
  align-items: start;
  gap: 1rem;
  margin: 2.375rem 0;
}

#BravectoExperience .card-container .card-sub-container .card-content .infos .address {
  max-height: 85px;
  height: 100%;
}

#BravectoExperience .card-container .card-sub-container .card-content .infos img {
  object-fit: contain;
}

#BravectoExperience .card-container .card-sub-container .card .location {
  border-top: 1px solid white;
  display: flex;
  width: 100%;
}

#BravectoExperience .card-container .card-sub-container .card .location .btn-loc {
  border-right: 1px solid white;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50%;
  padding: 1.125rem 0;
}

#BravectoExperience .card-container .card-sub-container .card .location .btn-loc:last-child {
  border-right: none;
}

#BravectoExperience .card-container .card-sub-container .card .location .btn-loc a {
  align-items: center;
  display: flex;
  color: #fff;
  gap: 1rem;
  font-size: 0.875rem;
  font-weight: bold;
  text-decoration: none;
}

#BravectoExperience .card-container button {
  background-color: #F4A53B;
  border: none;
  border-radius: 25px;
  color: #fff;
  cursor: pointer;
  font-size: 1rem;
  font-weight: 700;
  max-width: 304px;
  width: 100%;
  height: 49px;
  outline: none;
  margin-bottom: 3.125rem;
}

#BravectoExperience .card-container button:hover {
  background-color: #e69831;
  transition: all 0.4s;
}

@media screen and (max-width: 1024px) {
  #BravectoExperience .bg-lp::after {
    bottom: 3rem;
  }

  #BravectoExperience .info-sub-container {
    flex-direction: column;
    align-items: center;
    padding: 0 1.8rem;
    max-width: 750px;
  }

  #BravectoExperience .info-container .video::after {
    top: 19rem;
    right: 17.5rem;
    /* display: none; */
  }

  #BravectoExperience .info-container .video img {
    width: 100%;
    height: auto;
  }

  #BravectoExperience .info-container .info-content {
    max-width: none;
  }

  #BravectoExperience .lojas .lojas-sub {
    max-width: 750px;
  }

  .selects #container__select:nth-child(2) {
    width: 124px;
  }

  .selects #container__select:nth-child(3) {
    width: 132px;
  }

  .selects #container__select:nth-child(4) {
    width: 100px;
  }
}

@media screen and (max-width: 768px) {
  #BravectoExperience .bg-lp::after {
    bottom: 2.5rem;
    height: 45px;
  }

  #BravectoExperience .lojas .lojas-sub {
    padding: 0 1.8rem;
  }

  #BravectoExperience .lojas .lojas-sub .filtros-container .todos p {
    font-size: 0.75rem;
  }

  #BravectoExperience .lojas .lojas-sub .filtros-container {
    margin-top: 5rem;
    align-items: flex-start;
    height: 80px;
  }

  #BravectoExperience .lojas .lojas-sub .filtros-container .filtros .filter-desk {
    display: none;
  }

  #BravectoExperience .lojas .lojas-sub .filtros-container .filtros .filter-mob {
    position: relative;
    display: flex;
    flex-direction: column;
    gap: 0.6rem;
    border: 1px solid #D4D4D5;
    background-color: #fff;
    border-radius: 50px;
    text-align: center;
    transition: all 0.3s ease 0s;
    padding: 0.5rem;
    z-index: 10;
  }

  #BravectoExperience .lojas .lojas-sub .filtros-container .filtros .filter-mob .inputs-mob {
    margin-top: 1rem;
  }
}

@media screen and (max-width: 500px) {
  #BravectoExperience .bg-lp .banner-desk {
    display: none;
  }

  #BravectoExperience .bg-lp .banner-mob {
    display: flex;
    width: 100%;
  }

  #BravectoExperience .lojas .lojas-sub .title h1 {
    line-height: 43px;
  }

  #BravectoExperience .lojas .lojas-sub .title p {
    margin-top: 1.4rem;
  }

  #BravectoExperience .info-container .video::after {
    top: 19.5rem;
    right: 9.5rem;
  }
}

@media screen and (max-width: 375px) {
  #BravectoExperience .info-container .video::after {
    right: 8.5rem;
  }
}