.image-gallery-fullscreen-button::before,
.image-gallery-left-nav::before,
.image-gallery-play-button::before,
.image-gallery-right-nav::before {
    display: inline-block;
    font-family: Ionicons;
    speak: none;
    font-style: normal;
    font-weight: 400;
    font-variant: normal;
    text-transform: none;
    text-rendering: auto;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale
}

.image-gallery {
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    -o-user-select: none;
    user-select: none;
    -webkit-tap-highlight-color: transparent
}

.image-gallery.fullscreen-modal {
    background: #000;
    bottom: 0;
    height: 100%;
    left: 0;
    position: fixed;
    right: 0;
    top: 0;
    width: 100%;
    z-index: 5
}

.image-gallery.fullscreen-modal .image-gallery-content {
    top: 50%;
    transform: translateY(-50%)
}

.image-gallery-content {
    position: relative;
    line-height: 0;
    top: 0
}

.image-gallery-content.fullscreen {
    background: #000
}

.image-gallery-content.fullscreen .image-gallery-slide {
    background: #000
}

.image-gallery-slide-wrapper {
    position: relative
}

.image-gallery-slide-wrapper.left,
.image-gallery-slide-wrapper.right {
    display: inline-block;
    width: calc(100% - 113px)
}

@media (max-width:990px) {
    .image-gallery-slide-wrapper.left,
    .image-gallery-slide-wrapper.right {
        width: calc(100% - 84px)
    }
}

.image-gallery-slide-wrapper.image-gallery-rtl {
    direction: rtl
}

.image-gallery-fullscreen-button,
.image-gallery-left-nav,
.image-gallery-play-button,
.image-gallery-right-nav {
    appearance: none;
    background-color: transparent;
    border: 0;
    cursor: pointer;
    outline: 0;
    position: absolute;
    z-index: 4
}

.image-gallery-fullscreen-button::before,
.image-gallery-left-nav::before,
.image-gallery-play-button::before,
.image-gallery-right-nav::before {
    color: #fff;
    line-height: .7;
    text-shadow: 0 2px 2px #1a1a1a;
    transition: color .2s ease-out
}

.image-gallery-fullscreen-button:hover::before,
.image-gallery-left-nav:hover::before,
.image-gallery-play-button:hover::before,
.image-gallery-right-nav:hover::before {
    color: #337ab7
}

@media (max-width:990px) {
    .image-gallery-fullscreen-button:hover::before,
    .image-gallery-left-nav:hover::before,
    .image-gallery-play-button:hover::before,
    .image-gallery-right-nav:hover::before {
        color: #fff
    }
}

.image-gallery-fullscreen-button,
.image-gallery-play-button {
    bottom: 0
}

.image-gallery-fullscreen-button::before,
.image-gallery-play-button::before {
    font-size: 2.7em;
    padding: 15px 20px;
    text-shadow: 0 1px 1px #1a1a1a
}

@media (max-width:990px) {
    .image-gallery-fullscreen-button::before,
    .image-gallery-play-button::before {
        font-size: 2.4em
    }
}

@media (max-width:480px) {
    .image-gallery-fullscreen-button::before,
    .image-gallery-play-button::before {
        font-size: 2em
    }
}

.image-gallery-fullscreen-button:hover::before,
.image-gallery-play-button:hover::before {
    color: #fff;
    transform: scale(1.1)
}

@media (max-width:990px) {
    .image-gallery-fullscreen-button:hover::before,
    .image-gallery-play-button:hover::before {
        transform: none
    }
}

.image-gallery-fullscreen-button {
    right: 0
}

.image-gallery-fullscreen-button::before {
    content: ""
}

.image-gallery-fullscreen-button.active::before {
    content: ""
}

.image-gallery-fullscreen-button.active:hover::before {
    transform: scale(.9)
}

.image-gallery-play-button {
    left: 0
}

.image-gallery-play-button::before {
    content: ""
}

.image-gallery-play-button.active::before {
    content: ""
}

.image-gallery-left-nav,
.image-gallery-right-nav {
    color: #fff;
    font-size: 5em;
    padding: 50px 15px;
    top: 50%;
    transform: translateY(-50%)
}

.image-gallery-left-nav[disabled],
.image-gallery-right-nav[disabled] {
    cursor: disabled;
    opacity: .6;
    pointer-events: none
}

@media (max-width:990px) {
    .image-gallery-left-nav,
    .image-gallery-right-nav {
        font-size: 3.4em;
        padding: 20px 15px
    }
}

@media (max-width:480px) {
    .image-gallery-left-nav,
    .image-gallery-right-nav {
        font-size: 2.4em;
        padding: 0 15px
    }
}

.image-gallery-left-nav {
    left: 0
}

.image-gallery-left-nav::before {
    content: ""
}

.image-gallery-right-nav {
    right: 0
}

.image-gallery-right-nav::before {
    content: ""
}

.image-gallery-slides {
    line-height: 0;
    overflow: hidden;
    position: relative;
    white-space: nowrap
}

.image-gallery-slide {
    background: #fff;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%
}

@media only screen and (max-width: 990px) {
    .image-gallery-slide {
        background: #fff;
        left: 0;
        position: absolute;
        top: 0;
        width: 100%
    }
}

.image-gallery-slide.center {
    position: relative
}

.image-gallery-slide img {
    /* height: 28.125rem; */
    width: 100%;
}
@media only screen and (max-width: 990px) {
    .image-gallery-slide img {
        width: 100%;
    }
}


.image-gallery-slide .image-gallery-description {
    background: rgba(0, 0, 0, .4);
    bottom: 70px;
    color: #fff;
    left: 0;
    line-height: 1;
    padding: 10px 20px;
    position: absolute;
    white-space: normal
}

@media (max-width:990px) {
    .image-gallery-slide .image-gallery-description {
        bottom: 45px;
        font-size: .8em;
        padding: 8px 15px
    }
}

.image-gallery-bullets {
    bottom: 20px;
    left: 0;
    margin: 0 auto;
    position: absolute;
    right: 0;
    width: 80%;
    z-index: 4
}

.image-gallery-bullets .image-gallery-bullets-container {
    margin: 0;
    padding: 0;
    text-align: center
}

.image-gallery-bullets .image-gallery-bullet {
    appearance: none;
    background-color: #ffffff;
    height: 0.9375rem;
    width: 0.9375rem;
    border: 3px solid #fff;
    border-radius: 50%;
    /* box-shadow: 0 1px 0 #1a1a1a; */
    cursor: pointer;
    display: inline-block;
    margin: 0 5px;
    outline: 0;
    padding: 2px
}

@media (max-width:990px) {
    .image-gallery-bullets .image-gallery-bullet {
        margin: 0 3px;
        padding: 3px
    }
}

@media (max-width:480px) {
    .image-gallery-bullets .image-gallery-bullet {
        padding: 2.7px
    }
}

.image-gallery-bullets .image-gallery-bullet.active {
    background: #b42446;
}

.image-gallery-thumbnails-wrapper {
    position: relative
}

.image-gallery-thumbnails-wrapper.thumbnails-wrapper-rtl {
    direction: rtl
}

.image-gallery-thumbnails-wrapper.left,
.image-gallery-thumbnails-wrapper.right {
    display: inline-block;
    vertical-align: top;
    width: 108px
}

@media (max-width:990px) {
    .image-gallery-thumbnails-wrapper.left,
    .image-gallery-thumbnails-wrapper.right {
        width: 81px
    }
}

.image-gallery-thumbnails-wrapper.left .image-gallery-thumbnails,
.image-gallery-thumbnails-wrapper.right .image-gallery-thumbnails {
    height: 100%;
    width: 100%;
    left: 0;
    padding: 0;
    position: absolute;
    top: 0
}

.image-gallery-thumbnails-wrapper.left .image-gallery-thumbnails .image-gallery-thumbnail,
.image-gallery-thumbnails-wrapper.right .image-gallery-thumbnails .image-gallery-thumbnail {
    display: block;
    margin-right: 0;
    padding: 0
}

.image-gallery-thumbnails-wrapper.left .image-gallery-thumbnails .image-gallery-thumbnail+.image-gallery-thumbnail,
.image-gallery-thumbnails-wrapper.right .image-gallery-thumbnails .image-gallery-thumbnail+.image-gallery-thumbnail {
    margin-left: 0
}

.image-gallery-thumbnails-wrapper.left {
    margin-right: 5px
}

@media (max-width:990px) {
    .image-gallery-thumbnails-wrapper.left {
        margin-right: 3px
    }
}

.image-gallery-thumbnails-wrapper.right {
    margin-left: 5px
}

@media (max-width:990px) {
    .image-gallery-thumbnails-wrapper.right {
        margin-left: 3px
    }
}

.image-gallery-thumbnails {
    overflow: hidden;
    padding: 5px 0
}

@media (max-width:990px) {
    .image-gallery-thumbnails {
        padding: 3px 0
    }
}

.image-gallery-thumbnails .image-gallery-thumbnails-container {
    cursor: pointer;
    text-align: center;
    transition: transform .45s ease-out;
    white-space: nowrap
}

.image-gallery-thumbnail {
    display: inline-block;
    border: 4px solid transparent;
    transition: border .3s ease-out;
    width: 100px
}

@media (max-width:990px) {
    .image-gallery-thumbnail {
        border: 3px solid transparent;
        width: 75px
    }
}

.image-gallery-thumbnail+.image-gallery-thumbnail {
    margin-left: 2px
}

.image-gallery-thumbnail .image-gallery-thumbnail-inner {
    position: relative
}

.image-gallery-thumbnail img {
    vertical-align: middle;
    width: 100%
}

.image-gallery-thumbnail.active {
    border: 4px solid #337ab7
}

@media (max-width:990px) {
    .image-gallery-thumbnail.active {
        border: 3px solid #337ab7
    }
}

.image-gallery-thumbnail-label {
    box-sizing: border-box;
    color: #fff;
    font-size: 1em;
    left: 0;
    line-height: 1em;
    padding: 5%;
    position: absolute;
    top: 50%;
    text-shadow: 1px 1px 0 #000;
    transform: translateY(-50%);
    white-space: normal;
    width: 100%
}

@media (max-width:990px) {
    .image-gallery-thumbnail-label {
        font-size: .8em;
        line-height: .8em
    }
}

.image-gallery-index {
    background: rgba(0, 0, 0, .4);
    color: #fff;
    line-height: 1;
    padding: 10px 20px;
    position: absolute;
    right: 0;
    top: 0;
    z-index: 4
}

@media (max-width:990px) {
    .image-gallery-index {
        font-size: .8em;
        padding: 5px 10px
    }
}
/*
li,
ul {
    padding: 0;
    margin: 0;
    list-style: none
}

li {
    padding: 3px 0;
    display: inline-block
}

label {
    margin-left: 5px
}
*/
.app-header {
    letter-spacing: 1px;
    text-transform: uppercase
}

.play-button {
    cursor: pointer;
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    margin: auto;
    height: 60px;
    width: 100px;
    background-color: rgba(0, 0, 0, .7);
    border-radius: 5px
}

.play-button:hover {
    background-color: rgba(0, 0, 0, .9)
}

.play-button:after {
    content: "";
    display: block;
    position: absolute;
    top: 16.5px;
    left: 40px;
    margin: 0 auto;
    border-style: solid;
    border-width: 12.5px 0 12.5px 20px;
    border-color: transparent transparent transparent rgba(255, 255, 255, 1)
}

.close-video::before {
    content: '✖';
    cursor: pointer;
    position: absolute;
    right: 0;
    top: 0;
    font-size: 20px;
    padding: 20px;
    z-index: 1;
    line-height: .7;
    display: block;
    color: #fff
}

.video-wrapper {
    position: relative;
    padding: 33.35% 0;
    height: 0
}

.video-wrapper iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%
}

.app .image-gallery,
.app-sandbox {
    margin: 0 auto;
    width: 100%;
    transition: all 1s ease
}

@media (max-width:1320px) {
    .app-sandbox-content {
        padding: 0 20px
    }
}

.app-sandbox {
    margin: 40px auto;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    -o-user-select: none;
    user-select: none
}

.app-buttons li {
    display: block
}

@media (max-width:990px) {
    .app-header {
        font-size: 20px
    }
    .app-buttons li {
        display: block;
        margin: 10px 0
    }
    .app-buttons li+li {
        padding: 0
    }
    .play-button {
        height: 40px;
        width: 65px
    }
    .play-button:after {
        top: 11px;
        left: 27px;
        border-width: 8.5px 0 8.5px 12px
    }
    .close-video::before {
        font-size: 16px;
        padding: 15px
    }
}

@media (max-width:1024px) {
    .app .image-gallery,
    .app-sandbox {
        width: 100%
    }
}

.app-interval-input-group {
    display: table
}

.app-interval-label {
    display: table-cell;
    vertical-align: middle;
    padding: 6px 12px;
    font-size: 14px;
    font-weight: 400;
    line-height: 1;
    color: #555;
    text-align: center;
    background-color: #eee;
    border: 3px solid #ccc;
    border-right: none;
    border-radius: 4px;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0
}

.app-interval-input {
    -webkit-appearance: none;
    display: table-cell;
    margin: 0;
    padding: 9px;
    border-radius: 5px;
    font-size: 14px;
    border: 3px solid #ccc;
    background: #fff;
    width: 100%;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0
}

input.app-interval-input {
    width: 65px
}

.app-checkboxes {
    margin-top: 10px
}

.app-checkboxes li {
    display: block
}
