#modal--reminder {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow: hidden;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 2;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

#modal--reminder #modal--reminder__container {
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 50.188rem;
  /* height: 32.438rem; */
  box-shadow: 2px 1px 16px 14px rgb(0 0 0 / 20%);
  background-color: #f2f2f2;
  border-radius: 57px;
}

#modal--reminder #modal--reminder__container #img-cadastrar-mobile {
  display: none;
}

#modal--reminder #modal--reminder__container #modal--reminder__container--close {
  position: relative;
}

#modal--reminder #modal--reminder__container #modal--reminder__container--close button {
  position: absolute;
  background-color: inherit;
  border: none;
  cursor: pointer;
  right: -24rem;
  top: 1rem;
}

#modal--reminder #modal--reminder__container #modal--reminder__container--desc {
  align-items: center;
  display: flex;
  flex-direction: column;
  text-align: center;
  width: 80%;
}

#modal--reminder #modal--reminder__container #modal--reminder__container--desc h1 {
  font-size: 1.703rem;
  color: #ed2764;
}

#modal--reminder #modal--reminder__container #modal--reminder__container--desc h1 span {
  color: #54266e;
}

#modal--reminder #modal--reminder__container #modal--reminder__container--desc p {
  font-size: 1.049rem;
  margin: 1rem 0 0;
}

#modal--reminder #modal--reminder__container #modal--reminder__container--desc p span {
  color: #199cd2;
  font-weight: bold;
}

#modal--reminder #modal--reminder__container #modal--reminder__container--save {
  background-color: #ffa531;
  width: 55%;
  padding: 7px;
  border-radius: 50px;
  margin: 2.188rem 0 1.563rem;
}

#modal--reminder #modal--reminder__container #modal--reminder__container--save a {
  color: #fff;
  text-decoration: none;
  font-weight: bold;
}

@media only screen and (max-width: 768px) {
  #modal--reminder #modal--reminder__container {
    width: 24.563rem;
  }

  #modal--reminder #modal--reminder__container #modal--reminder__container--desc h1 {
    font-size: 1.452rem;
  }

  #modal--reminder #modal--reminder__container #modal--reminder__container--desc {
    width: 90% !important;
  }

  #modal--reminder #modal--reminder__container #modal--reminder__container--save {
    width: 75%;
    padding: 3px;
  }

  #modal--reminder #modal--reminder__container #img-cadastrar-desk {
    display: none;
  }

  #modal--reminder #modal--reminder__container #img-cadastrar-mobile {
    display: block;
    width: 100%;
  }

  #modal--reminder #modal--reminder__container #modal--reminder__container--close button {
    right: -11.5rem;
    top: 1.5rem;
  }
}

@media only screen and (max-width: 375px) {
  #modal--reminder #modal--reminder__container {
    width: 18.563rem;
  }

  #modal--reminder #modal--reminder__container #modal--reminder__container--close button {
    right: -8.5rem;
    top: 0.9rem;
  }
}