@mixin flexColumnCenter {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

@mixin flexColumnStretch {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: center;
}

@mixin flexRowCenter {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

@mixin flexRowCenterRight {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
}

@mixin preLoader {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 10rem;
}

@mixin widthHeight100 {
  width: 100%;
  height: 100%;
}
