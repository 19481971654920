@mixin defaultBtn {
  height: 2.8125rem;
  background-color: #b42446;
  color: #ffffff;
  font-size: 0.9rem;
  font-weight: 500;
  font-family: Helvetica, Arial;
  border-radius: 1.5rem;
  border: 0px;
  width: 16.5rem;
  margin-bottom: 2.5rem;
  letter-spacing: 0.03125rem;
  cursor: pointer;

  &:focus {
    outline: none;
  }

  &:hover {
    background-color: #a12e4a;
  }
}
