.confirm-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(75, 85, 99, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 10;
}

.confirm-modal-content {
  background-color: #B32346;
  color: white;
  padding: 16px;
  border-radius: 8px;
  box-shadow: 0px 10px 15px rgba(0, 0, 0, 0.1);
  min-height: 400px;
  max-width: 400px;
  width: 100%;
  padding: 20px 40px;
  display: flex;
  flex-direction: column;
}

.confirm-modal-content p {
  margin: 32px 0;
}

.confirm-modal-content h3 {
  width: 350px;
}

.confirm-modal-buttons {
  margin-top: 48px;
  display: flex;
  justify-content: space-between;
}

.confirm-modal-button {
  padding: 8px 16px;
  border-radius: 16px;
  color: #ffffff;
  border: none;
  cursor: pointer;
  font-weight: bold;
  transition: transform 0.1s ease-in-out;
}

.confirm-modal-button:hover {
  transform: scale(1.05);
}

.confirm-modal-button.confirm {
  background-color: #B32346;
  color: white;
  border: 1px solid white;
  width: 100%;
  max-width: 120px;
}

.confirm-modal-button.cancel {
  background-color: white;
  color: #B32346;
}

.confirm-modal-close-icon svg {
  transform: scale(2);
  cursor: pointer;
  transition: transform 0.2s ease-in-out;
}

.confirm-modal-close-icon svg:hover {
  transform: scale(2.2);
}

.confirm-modal-close-icon {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  margin-bottom: 24px;
}

.confirm-modal-border-detail {
  border-bottom: 8px solid #F9A11B;
  border-radius: 24px;
  width: 90px;
}

@media (max-width: 600px) {
  .confirm-modal-buttons {
    display: flex;
    flex-direction: column;
    gap: 20px;
  }

  #SubirNota > div.confirm-modal-overlay > div > div.confirm-modal-buttons > button.confirm-modal-button.confirm {
    min-width: 100%;
  }
}

@media (max-width: 600px) {
  .confirm-modal-content {
    min-width: 60%;
    max-width: 60%;
  }

  .confirm-modal-content h3 {
    max-width: 100%;
    max-height: 100vh;
  }

  .confirm-modal-content p {
    margin: 16px 0;
  }

  .confirm-modal-buttons {
    margin-top: 16px;
  }
}