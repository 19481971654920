@import "../../utils/styles/_styles.scss";

#Faq {
  @include widthHeight100;
  background-color: #f5f7fb;

  #faq__container {
    @include flexColumnCenter;

    #title {
      width: 100%;
      margin: 2rem 0 2rem 0;

      #main-title {
        display: flex;
        justify-content: center;
        text-align: center;

        span {
          @include helveticaMedium;
          @include bravectoGradient;

          font-size: 1.25rem;
          letter-spacing: 0.03125rem;
        }
      }

      #info {
        text-align: center;
        span {
          @include helveticaLight;
          font-size: 0.75rem;
          color: #0a0b0c;

          @media only screen and (max-width: 990px) {
            font-size: 0.8rem;
          }
        }
      }
    }

    #question {
      @include widthHeight100;
      @include flexColumnCenter;
      margin-bottom: 2rem;

      #question__container {
        height: 100%;
        width: 80%;

        @media only screen and (max-width: 990px) {
          @include widthHeight100;
        }

        .question__content--open {
          border: 1.5rem solid #ffffff;
          background-color: #ffffff;
        }

        .question__content--closed {
          border: 1.5rem solid #f5f7fb;
          background-color: #f5f7fb;
        }

        #question__content {
          #question__title {
            @include widthHeight100;
            display: flex;
            justify-content: flex-start;
            align-items: center;
            font-size: 1.2rem;

            @media only screen and (max-width: 990px) {
              font-size: 1rem;
            }

            #icon {
              display: flex;
              align-items: center;
              justify-content: center;
              width: 2rem;
              height: 2rem;
              background-color: #b42446;
              border-radius: 50%;
              margin-right: 1rem;

              span {
                @include helveticaMedium;
                color: #ffffff;
              }
            }

            #main-title {
              @include helveticaBold;
              width: 80%;
            }

            #icon-arrow {
              display: flex;
              justify-content: flex-end;
              width: 15%;
              cursor: pointer;

              svg {
                height: 3rem;
                width: 3rem;
                color: #b42446;
                cursor: pointer;

                @media only screen and (max-width: 990px) {
                  height: 1.5rem;
                  width: 1.5rem;
                }
              }
            }
          }

          .answer--closed {
            max-height: 0 !important;
          }

          #answer {
            @include helveticaLight;
            overflow-y: hidden;
            margin: 1rem 0 0 0;
            font-size: 1rem;
            line-height: 1.5;
            color: #5f5f5f;

            @media only screen and (max-width: 990px) {
              font-size: 0.8rem;
            }

            max-height: 20rem;

            transition-property: all;
            transition-duration: 0.5s;
            transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
          }
        }

        @media only screen and (min-width: 768px) {
          #icon-arrow {
            display: none !important;
          }

          #question__content {
            border: 0;
            padding: 1.5rem;
            border-bottom: 2px solid #b42446;

            .answer--closed {
              max-height: initial !important;
            }

            &:last-child {
              border-bottom: 0;
            }
          }

          .question__content--open {
            background-color: transparent;
          }
        }
      }
    }
  }
}
