#MomentosBravecto10Anos .bg-lp .banner-desk {
  width: 100%;
  display: block;
}

#MomentosBravecto10Anos .bg-lp .banner-mob {
  display: none;
}

#MomentosBravecto10Anos {
  background-color: white;
}

#MomentosBravecto10Anos .FirstContainer > div > div {
  display: flex;
  justify-content: center;
  align-items: center;
}

#MomentosBravecto10Anos .FirstContainer {
  padding: 50px 0 0 0;
}

#MomentosBravecto10Anos .FirstContainer > div > div > div > h1 {
  color: #4D245F;
  font-weight: 800;
  font-size: 44px;
  width: 95%;
  word-spacing: 2px;
  max-width: 800px;
}

.small-symbol {
  font-size: 0.50em;
  vertical-align: super;
}

#MomentosBravecto10Anos .FirstContainer > div > div > div > p {
  color: white;
  font-size: 24px;
  font-weight: 300;
  max-width: 900px;
  word-spacing: 2px;
  padding: 0 0 20px 0;
}

#MomentosBravecto10Anos > div.FirstContainer > div:nth-child(1) {
  display: flex;
  justify-content: center;
}

#MomentosBravecto10Anos > div.FirstContainer > div:nth-child(1) > div {
  width: 90%;
  margin-bottom: 50px;
}

#MomentosBravecto10Anos > div.FirstContainer > div:nth-child(1) > div > img {
  width: 40%;
  max-width: 520px;
}

#MomentosBravecto10Anos .btn-cta {
  color: white;
  background-color: #D02D59;
  font-size: 32px;
  font-weight: 600;
  text-align: center;
  padding: 20px 80px;
  border-radius: 40px;
  width: fit-content;
}

.btn-cta-container {
  display: flex;
  width: 100%;
  justify-content: center;
  position: relative;
  bottom: -32px;
}

#MomentosBravecto10Anos .FirstContainer {
  background-color: #2fbab8;
}

#MomentosBravecto10Anos .link-cta {
  background-color: #2fbab8;
}

.second-container {
  background-color: #F5F5F5;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 40px;
  padding-top: 150px;
}

.second-container > div {
  width: 90%;
  gap: 20px;
  justify-content: center;
}

.second-container-first-sub, .second-container-second-sub, .second-container-third-sub  {
  display: flex;
}

.second-container-first-sub > div, .second-container-second-sub > div, .second-container-third-sub > div  {
  width: 50%;
}

.second-container-first-sub > img, .second-container-third-sub > img  {
  height: 56px;
}

.second-container-second-sub > img {
  height: 42px;
}

.second-container > .second-container-first-sub > div > p {
  color: #7C4E9B;
  font-size: 32px;
  font-weight: bold;
}

.second-container > .second-container-first-sub > div > span {
  color: #7C4E9B;
  font-size: 24px;
  font-weight: 300;
}

.second-container > .second-container-second-sub > div > p {
  color: #15A0DB;
  font-size: 32px;
  font-weight: bold;
}

.second-container > .second-container-second-sub > div > span {
  color: #15A0DB;
  font-size: 24px;
  font-weight: 300;
}

.second-container > .second-container-third-sub > div > p {
  color: #ED2764;
  font-size: 32px;
  font-weight: 300;
}

.second-container > .second-container-third-sub > div > p > span {
  color: #ED2764;
  font-size: 32px;
  font-weight: bold;
}

.second-container > a {
  color: #858585;
  font-size: 24px;
  font-weight: 300;
  width: 40%
}

.second-container-btn-cta-box {
  display: flex;
  justify-content: center;
  text-decoration: none;
}

.second-container-btn-cta {
  background-color: #2FBAB8;
  color: white;
  font-weight: bold;
  font-size: 20px;
  padding: 20px 40px;
  border-radius: 32px;
  border: none;
  cursor: pointer;
}

.third-container {
  padding-top: 125px;
  background-color: white;
}

.third-container > div {
  display: flex;
  justify-content: center;
}

.third-container > div > img {
  width: 35%;
}


.third-container > div > div {
  color: #4D245F;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: right;
}

#MomentosBravecto10Anos > div.third-container > div:nth-child(1) > div {
  width: 35%;
}

.third-container > div > div > p {
  color: #4D245F;
  font-size: 32px;
  font-weight: 300;
}

.third-container > div > div > p:nth-child(2) {
  color: #4D245F;
  font-weight: bold;
  padding-top: 20px;
}

.third-container > div > div > p:nth-child(3) {
  color: #D02D59;
  font-size: 32px;
  font-weight: bold;
  padding-top: 75px;
}

.third-container > div > div > p:nth-child(4) {
  color: #D02D59;
  font-size: 26px;
  font-weight: 300;
}

.fourth-container {
  padding-top: 75px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #4B235D;
  background: linear-gradient(to right, #4B235D, #129C9A);
  color: white;
}

.fourth-container > p {
  width: 70%;
  font-size: 32px;
  font-weight: 300;
  word-spacing: 2px;
}

.fourth-container > p:nth-child(3) {
  padding-top: 75px;
  padding-bottom: 25px;
  font-weight: bold;
  background: linear-gradient(to right, #7390DF 30%, #47EFFF 80%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  text-align: center;
  font-size: 44px;
}

.fourth-container > p:nth-child(3) span {
  font-weight: 300;
  background: linear-gradient(to right, #62B5F2, #47EFFF);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.fourth-container > p:nth-child(3) span:nth-child(1) {
  font-weight: 300;
  background: #7390DF;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.fourth-container-card-box {
  display: flex;
  flex-wrap: wrap;
  gap: 40px;
  justify-content: center;
  padding-top: 50px;
  width: 80%;
}

.fourth-container-card {
  width: 25%;
}

.fifth-container {
  background-color: #4B235D;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.fifth-container img {
  width: 30%;
  padding-top: 50px;
}

.fifth-container p {
  width: 70%;
}

.fifth-container > p:nth-child(2) {
  color: white;
  font-size: 32px;
  font-weight: 300;
  padding-bottom: 50px;
}

.fifth-container .fifth-container-question {
  font-size: 32px;
  color: #D02D59;
  font-weight: bold;
  padding: 20px 0;
}

.fifth-container .fifth-container-answer {
  color: #2FBAB8;
  font-size: 32px;
  font-weight: 300;
}

.fifth-container .fifth-container-answer:nth-child(8) {
  color: #2FBAB8;
  font-size: 32px;
  line-height: 45px;
  padding-bottom: 100px;
}

.fifth-container .fifth-container-answer:nth-child(8) span {
  font-weight: bold;
}

.sixth-container {
  background-color: #2FBAB8;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: 100px;
}

.sixth-container p span {
  font-weight: bold;
}

.sixth-container p {
  font-size: 48px;
  text-align: center;
  font-weight: 300;
  color: white;
}


.sixth-container-card-box {
  width: 70%;
  display: flex;
  flex-direction: column;
}


.sixth-container-card {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.winner-title {
  background-color: #2FBAB8;
  color: white;
  padding: 10px 20px;
  border-radius: 60px;
  font-weight: bold;
  font-size: 24px;
}

.winner-user {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: repeat(3, 1fr);
  gap: 40px;
  max-width: 700px;
}

.winner-user > div > p:nth-child(1) {
  color: #4D245F;
  padding: 20px 0 0 20px;
  font-size: 24px;
}

.winner-user > div > p:nth-child(2) {
  color: #4D245F;
  padding: 0 0 0 20px;
  font-size: 24px;
  font-weight: normal;
}

#MomentosBravecto10Anos > div.second-container > div > div:nth-child(1) > div > p > span:nth-child(4) {
  font-weight: normal;
}


.sixth-container-card img {
  width: 20%;
  min-width: 250px;
  position: relative;
  bottom: 100px;
}

.sixth-container-card p {
  font-weight: bold;
  color: #2FBAB8;
  padding: 30px 0 0 20px;
  border-radius: 60px;
}

#sixth-container-card-title {
  background-color: white;
  color: #4B235D;
}

#sixth-container-card-plus {
  border: 4px solid white;
  color: white;
}

.sixth-container-card .sixth-container-card-description {
  background-color: #1F9391;
  font-size: 20px;
}

.sixth-container-card .sixth-container-card-description {
  font-weight: 300;
  top: -35px;
  position: relative;
  z-index: 0;
}

#sixth-container-regulamento {
  font-size: 16px;
  padding: 50px 180px;
  color: #9b9b9b;
  font-weight: 300;
}

#sixth-container-regulamento > span:nth-child(2) {
  color: #767676;
}

.faq-question p:nth-child(1) {
}

.faq-question p:nth-child(2) {
  border: 1px solid #D3D3D3;
  padding: 20px;
  border-top: none;
}

.faq-question {
  width: 80%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 10px;
  grid-gap: 0;
}

.faq-question div {
  display: flex;
  font-weight: bold;
  border: 1px solid #D3D3D3;
  padding: 10px 20px;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
}

.faq-question svg {
  width: 40px;
}


.seventh-container {
  padding: 150px 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 25px;
}

.oitava-section {
  color: white;
  font-size: 50px;
  text-align: center;
  padding: 50px;
  font-weight: bold;
  background-color: #D02E5A;
}

.nona-section {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: 100px;
}

.nona-section > div {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border: 4px solid #592E81;
  width: 750px;
  padding: 40px;
  border-radius: 24px;
  gap: 10px;
}

.nona-section > div p:nth-child(1) {
  color: #2FBAB8;
  font-size: 26px;
  padding-top: 20px;
  font-weight: bold;
}


.nona-section > div p:nth-child(2) {
  color: #592E81;
  letter-spacing: 1px;
}

.nona-section > div p:nth-child(3) {
  color: #ED2764;
  font-weight: bold;
  letter-spacing: 1px;
}

.nona-section > div a:nth-child(4) {
  color: white;
  background-color: #592E81;
  text-decoration: none;
  padding: 10px 40px;
  border-radius: 24px;
  font-weight: bold;
  margin-top: 20px;
}

.nona-section > img {
  position: relative;
  bottom: -40px;
  background: white;
  padding: 0 10px;
  width: 80px;
}

#MomentosBravecto10Anos > div.nona-section > a {
  margin: 50px 0;
  color: #858585;
  font-size: 24px;
  font-weight: bold;
}

#MomentosBravecto10Anos > div.nona-section > p {
  color: #858585;
  font-size: 24px;
  font-weight: 300;
  margin-bottom: 40px;
}

#MomentosBravecto10Anos > div.third-container > div {
  width: 100%;
  display: flex;
}

#MomentosBravecto10Anos > div.second-container > a.second-container-btn-cta-box > button:hover {
  background-color: #1f9e9b;
  transition: 200ms;
}

#MomentosBravecto10Anos > div.nona-section > div > a:hover {
  background-color: #7840ad;
  transition: 200ms;
}

@media screen and (max-width: 1024px) {
  #MomentosBravecto10Anos .bg-lp::after {
    bottom: 3rem;
  }

  .sixth-container-card {
    display: flex;
    flex-direction: column;
  }

  .sixth-container-card img {
    position: static;
  }

  .sixth-container-card p {
    padding: 20px 0;
  }

  .winner-user {
    display: flex;
    flex-direction: column;
  }

  .winner-user > div {
    text-align: start;
  }

  #MomentosBravecto10Anos > div.second-container > div > div:nth-child(1) > div {
    align-items: center;
    justify-content: center;
    display: flex;
    flex-direction: column;
  }

  #MomentosBravecto10Anos > div.FirstContainer > div:nth-child(1) > div {
    display: flex;
    flex-direction: column;
  }

  #MomentosBravecto10Anos > div.FirstContainer > div:nth-child(1) > div > div {
    text-align: center;
    width: 90%;
  }

  #MomentosBravecto10Anos > div.FirstContainer > div:nth-child(1) > div > div > h1 {
    width: 100%
  }

  #MomentosBravecto10Anos > div.FirstContainer > div:nth-child(1) > div > div > p:nth-child(3) {
    width: 100%
  }

  #MomentosBravecto10Anos > div.FirstContainer > div:nth-child(1) > div > div > p:nth-child(4) {
    width: 100%
  }

  #MomentosBravecto10Anos > div.sixth-container > div.sixth-container-card-box {
    display: flex;
    flex-direction: column;
  }

  #MomentosBravecto10Anos > div.third-container > div:nth-child(1) {
    display: flex;
    flex-direction: column-reverse;
    align-items: center;
  }

  #MomentosBravecto10Anos > div.third-container > div:nth-child(1) > div {
    width: 80%;
    text-align: center;
  }
  
  #MomentosBravecto10Anos > div.fourth-container > div.fourth-container-card-box > img {
    width: 40%;
  }

  #MomentosBravecto10Anos > div.third-container > div:nth-child(1) > img {
    padding: 0;
    padding-top: 50px;
    width: 50%;
  }


  .second-container-first-sub, .second-container-second-sub, .second-container-third-sub  {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .second-container-first-sub > img, .second-container-third-sub > img  {
    width: 15%;
    height: 15%;
  }

  .second-container-second-sub > img {
    width: 15%;
    height: 15%;
  }

  .nona-section > div {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border: 4px solid #592E81;
    max-width: 70%;
    padding: 40px;
    border-radius: 24px;
    gap: 10px;
  }

  #sixth-container-regulamento {
    padding: 30px 0;
  }

  .second-container > div > div {
    text-align: center;
  }

}

@media screen and (max-width: 500px) {
  #MomentosBravecto10Anos .bg-lp .banner-desk {
    display: none;
  }

  #MomentosBravecto10Anos .bg-lp .banner-mob {
    display: flex;
    width: 100%;
  }

  #MomentosBravecto10Anos > div.FirstContainer > div:nth-child(1) > div > img {
    width: 100%;
  }

  #MomentosBravecto10Anos > div.FirstContainer > div:nth-child(1) > div > div > h1 {
    font-size: 24px;
    text-align: left;
  }

  #MomentosBravecto10Anos > div.FirstContainer > div:nth-child(1) > div > div > p {
    font-size: 18px;
    text-align: left;
  }

  .btn-cta-container {
    width: 100%;
    position: relative;
  }

  #MomentosBravecto10Anos div.btn-cta-container > p {
    max-width: 80%;
    width: 80%;
    font-size: 18px;
    padding: 20px 0;
  }

  #MomentosBravecto10Anos > div.sixth-container > p:nth-child(1) {
    font-size: 32px;
  }


  .sixth-container-card-box {
    width: 100%;
  }

  .sixth-container-card {
    max-width: 100%;
  }

  #MomentosBravecto10Anos > div.sixth-container > div.sixth-container-card-box > div:nth-child(1) > p:nth-child(2) {
    font-size: 26px;
  }

  #sixth-container-regulamento {
    text-align: left;
    font-size: 16px;
    padding: 0 24px;
  }

  #MomentosBravecto10Anos > div.third-container > div:nth-child(1) > div > p:nth-child(1) {
    font-size: 18px;
  }

  #MomentosBravecto10Anos > div.third-container > div:nth-child(1) > div > p:nth-child(2) {
    font-size: 18px;
  }

  #MomentosBravecto10Anos > div.third-container > div:nth-child(1) > div > p:nth-child(3) {
    font-size: 22px;
  }

  #MomentosBravecto10Anos > div.third-container > div:nth-child(1) > div > p:nth-child(4) {
    font-size: 22px;
  }

  #MomentosBravecto10Anos > div.third-container > div:nth-child(1) > img {
    width: 100%
  }

  #MomentosBravecto10Anos > div.fourth-container > p:nth-child(1) {
    font-size: 18px;
  }

  #MomentosBravecto10Anos > div.fourth-container {
    background: linear-gradient(to bottom right, #4B235D, #129C9A);
  }

  #MomentosBravecto10Anos > div.fourth-container > div.fourth-container-card-box {
    gap: 20px;
  }

  #MomentosBravecto10Anos > div.fourth-container > div.fourth-container-card-box > img {
    width: 45%
  }

  #MomentosBravecto10Anos > div.fourth-container > p:nth-child(3) {
    font-size: 20px;
    background: linear-gradient(to right, #2DFFFF 30%, #47EFFF 80%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }

  #MomentosBravecto10Anos > div.fourth-container > p:nth-child(3) span {
    font-size: 20px;
    background: linear-gradient(to right, #2DFFFF 30%, #47EFFF 80%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }

  #MomentosBravecto10Anos > div.fourth-container > p:nth-child(3) > span:nth-child(1) {
    font-weight: 200;
  }

  #MomentosBravecto10Anos > div.fifth-container > img {
    width: 80%;
  }

  #MomentosBravecto10Anos > div.fifth-container > p:nth-child(2) {
    font-size: 22px;
  }

  #MomentosBravecto10Anos > div.fifth-container .fifth-container-question {
    font-size: 20px;
  }

  #MomentosBravecto10Anos > div.fifth-container .fifth-container-answer {
    font-size: 20px;
  }

  #MomentosBravecto10Anos > div.oitava-section {
    font-size: 32px;
  }

  #MomentosBravecto10Anos > div.nona-section > div {
    width: 60%;
  }

  #MomentosBravecto10Anos > div.nona-section > div > a {
    margin-top: 10px;
  }

  .second-container > .second-container-first-sub > div > p {
    font-size: 20px;
  }

  .second-container > .second-container-second-sub > div > p {
    font-size: 20px;
  }

  .second-container > .second-container-third-sub > div > p {
    font-size: 20px;
  }

  .second-container > div > div {
    width: 100%;
    text-align: center;
    display: flex;
    flex-direction: column;
    gap: 10px;
    align-items: center;
  }

  #MomentosBravecto10Anos > div.second-container > div.second-container-second-sub > div > span {
    font-size: 16px;
  }

  #MomentosBravecto10Anos > div.second-container > div.second-container-first-sub > div > span {
    font-size: 16px;
  }

  #MomentosBravecto10Anos > div.second-container > div.second-container-third-sub > div > p > span {
    font-size: 20px;
  }

  #MomentosBravecto10Anos > div.second-container > div.second-container-third-sub > div > p > br {
    display: none;
  }

  #MomentosBravecto10Anos > div.second-container > a.second-container-btn-cta-box {
    width: 80%;
  }

  #MomentosBravecto10Anos > div.second-container > a:nth-child(4) {
    width: 80%;
    text-align: center;
  }

  #MomentosBravecto10Anos > div.nona-section > p {
    text-align: center;
    font-size: 12px;
  }

  #MomentosBravecto10Anos > div.nona-section > div > p:nth-child(2) > br:nth-child(2) {
    display: none;
  }

  #MomentosBravecto10Anos > div.nona-section > div > p:nth-child(1) {
    font-size: 22px;
  }


  #MomentosBravecto10Anos > div.nona-section > div {
    text-align: center;
  }

  #MomentosBravecto10Anos > div.nona-section > div > p:nth-child(3) {
    font-size: 14px;
  }

  @media screen and (max-width: 380px) {
    #MomentosBravecto10Anos > div.fourth-container > p:nth-child(3) {
      font-size: 12px;
    }

    #MomentosBravecto10Anos > div.fourth-container > p:nth-child(3) span {
      font-size: 12px;
    }
  }
}