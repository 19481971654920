.fade-enter {
    opacity: 0;
    z-index: 1;
}

.fade-exit {
    opacity: 0;
}

.fade-enter.fade-enter-active {
    opacity: 1;
    transition: opacity 500ms ease-in;
}