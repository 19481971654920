#modal--reminder--pet {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow: hidden;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 2;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  /* flex-direction: column; */
  align-items: center;
  justify-content: center;
}

#modal--reminder--pet #modal--reminder--pet__container {
  display: flex;
  align-items: center;
  flex-direction: row;
  width: 50.124rem;
  background-color: #f2f2f2;
  border-radius: 57px;
  box-shadow: 2px 1px 16px 14px rgb(0 0 0 / 20%);
  /* height: 32.438rem; */
}

#modal--reminder--pet #modal--reminder--pet__container #img-cadastrar-mobile {
  display: none;
}

#modal--reminder--pet #modal--reminder--pet__container #modal--reminder--pet__container--close {
  position: relative;
}

#modal--reminder--pet #modal--reminder--pet__container #modal--reminder--pet__container--close button {
  position: absolute;
  background-color: inherit;
  border: none;
  cursor: pointer;
  right: -49rem;
  top: -9.7rem;
}

#modal--reminder--pet #modal--reminder--pet__container #modal--reminder--pet__container--desc {
  display: flex;
  flex-direction: column;
  text-align: center;
  width: 52%;
}

#modal--reminder--pet #modal--reminder--pet__container #modal--reminder--pet__container--desc #modal--reminder--pet__container--info {
  margin-left: 3.688rem;
  text-align: left;
}

#modal--reminder--pet #modal--reminder--pet__container #modal--reminder--pet__container--desc h1 {
  margin: 0;
  font-size: 2.138rem;
  color: #ed2764;
}

#modal--reminder--pet #modal--reminder--pet__container #modal--reminder--pet__container--desc p {
  margin: 0;
  font-size: 1.238rem;
}

#modal--reminder--pet #modal--reminder--pet__container #modal--reminder--pet__container--desc p span {
  color: #199cd2;
  font-weight: bold;
}

#modal--reminder--pet #modal--reminder--pet__container #modal--reminder--pet__container--save {
  background-color: #ffa531;
  border-radius: 50px;
  text-align: center;
  padding: 7px;
  width: 12.451rem;
  margin: 1.4rem 3.688rem;
}

#modal--reminder--pet #modal--reminder--pet__container #modal--reminder--pet__container--save a {
  color: #fff;
  text-decoration: none;
  font-weight: bold;
}

@media only screen and (max-width: 768px) {
  #modal--reminder--pet #modal--reminder--pet__container #modal--reminder--pet__container--desc {
    align-items: center;
    width: 85%;
  }

  #modal--reminder--pet #modal--reminder--pet__container {
    width: 24.563rem;
    flex-direction: column;
  }

  #modal--reminder--pet #modal--reminder--pet__container #modal--reminder--pet__container--desc #modal--reminder--pet__container--info {
    text-align: center;
    margin: 2.25rem 0 0;
    align-items: center;
    display: flex;
    flex-direction: column;
  }

  #modal--reminder--pet #modal--reminder--pet__container #modal--reminder--pet__container--desc h1 {
    font-size: 2.414rem;
  }

  #modal--reminder--pet #modal--reminder--pet__container #modal--reminder--pet__container--save {
    width: 13.096rem;
    padding: 10px;
    margin: 1.5rem 0 3rem;
  }

  #modal--reminder--pet #modal--reminder--pet__container #img-cadastrar-desk {
    display: none;
  }

  #modal--reminder--pet #modal--reminder--pet__container #img-cadastrar-mobile {
    display: block;
    width: 100%;
  }

  #modal--reminder--pet #modal--reminder--pet__container #modal--reminder--pet__container--close button {
    right: -11.5rem;
    top: 1.5rem;
  }
}

@media only screen and (max-width: 375px) {
  #modal--reminder--pet #modal--reminder--pet__container {
    width: 18.563rem;
  }

  #modal--reminder--pet #modal--reminder--pet__container #modal--reminder--pet__container--close button {
    right: -8.5rem;
    top: 0.9rem;
  }
}