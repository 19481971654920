// @import url("https://fonts.googleapis.com/css2?family=Source+Sans+Pro:wght@400;500;600;700&display=swap");

$blue-500: #5f7f93;
$gray-200: #f5f5f5;

#SurePetCare {
  .SurePetCare--container {
    font-family: Helvetica, Arial;
    padding: 0 0.9375rem;
    color: $blue-500;
    background-color: $gray-200;

    h1,
    h3 {
      padding-bottom: 0.3125rem;
      border-bottom: 0.125rem solid $blue-500;
    }

    h1 {
      font-size: 1.1rem;
      font-weight: 600;
      line-height: 1.4;
    }

    h2 {
      font-size: 1.52rem;
      font-weight: 500;
      line-height: 1.4;
    }

    .subtitle {
      display: flex;
      align-items: center;
      gap: 0.9375rem;

      img {
        aspect-ratio: 1;
        height: 45px;
      }

      p {
        font-weight: bold;
        line-height: 1.4;
      }
    }

    a {
      grid-area: button;
      display: block;
      text-decoration: none;
      font-weight: bold;
      background: $blue-500;
      text-transform: uppercase;
      text-align: center;
      color: white;
      border-radius: 111px;
      border: none;
      padding: 15px 3rem;
      height: fit-content;
    }

    .product {
      display: grid;
      grid-template-columns: 1fr;
      grid-template-areas:
        "title"
        "description"
        "image"
        "button";

      h3 {
        grid-area: title;
        font-size: 2rem;
        margin-bottom: 0;

        span {
          font-weight: normal;
        }
      }

      p {
        grid-area: description;
        font-size: 1.5rem;
        margin-bottom: 0;
      }

      .product-image {
        grid-area: image;
        display: flex;
        flex-direction: column;
        gap: 2em;
        margin: 2em 0;

        img {
          width: 100%;
          height: auto;
        }

        span {
          display: flex;
          justify-content: center;
          font-size: 0.7rem;
          font-weight: bold;
          text-align: center;
          letter-spacing: 1px;
          width: 55%;
          margin: 0 auto;
          border: 1px solid $blue-500;
          background-color: transparent;
          color: $blue-500;
          border-radius: 111px;
          padding: 0.625rem 1.5625rem;
        }
      }
    }

    .product-details {
      display: grid;
      grid-template-columns: 1fr;
      grid-template-areas:
        "title"
        "description"
        "informations"
        "image"
        "button";
      margin-bottom: 1rem;

      &.no-title {
        margin-top: 2rem;
      }

      &.no-description {
        h4 {
          margin-bottom: 0;
        }
      }

      h4 {
        grid-area: title;
        font-size: 1.75rem;
        font-weight: 700;
        margin-bottom: 1rem;
      }

      p {
        grid-area: description;
        font-size: 1.08rem;
        font-weight: 500;
        margin: 0.5rem 0 0;
      }

      dl {
        grid-area: informations;
        margin: 0;

        dt {
          margin-top: 2rem;

          h5 {
            font-size: 1.2rem;
            font-weight: 600;
            margin: 0.5rem 0 0;
          }

          p {
            font-size: 1.05rem;
            font-weight: 500;
            margin: 0;
          }
        }
      }

      > img {
        grid-area: image;
        margin: 2rem 0;
        width: 100%;
        height: auto;
      }

      a {
        padding: 0.625rem 3rem;
      }
    }

    @media screen and (min-width: 768px) {
      max-width: 720px;
      margin: 0 auto;

      h2 {
        font-size: 1.8rem;
        padding: 0 0.9375rem;
      }

      .subtitle {
        padding: 0 1.875rem;

        img {
          height: 65px;
        }

        p {
          font-size: 1.5rem;
          font-weight: 600;
        }
      }

      .product {
        padding: 0 1.875rem;
        grid-template-columns: 1fr 1fr;
        grid-template-areas:
          "title image"
          "description image"
          "button image";
        grid-template-rows: auto auto 1fr;
        column-gap: 1.875rem;

        h3 {
          margin-bottom: 0.25rem;
        }

        p {
          font-size: 1.6em;
          margin-bottom: 1.5rem;

          &.break-on-strong {
            strong {
              display: block;
            }
          }
        }

        a {
          display: flex;
          width: fit-content;
          padding: 0.625rem 3.125rem;
        }

        .product-image {
          margin: 2rem 0;
        }
      }

      .product-details {
        grid-template-columns: 1fr 1fr;
        grid-template-areas:
          "title image"
          "description image"
          "informations image"
          "informations button";
        grid-template-rows: auto auto auto 1fr;
        column-gap: 1rem;
        margin-bottom: 3rem;

        &.no-title {
          grid-template-areas:
            "description image"
            "informations image"
            "informations button";
          grid-template-rows: auto auto 1fr;

          dl {
            dt {
              p {
                font-size: 0.8rem;
              }
            }
          }

          > img {
            margin-top: 4.5rem;
          }
        }

        &.no-description {
          grid-template-areas:
            "title image"
            "informations image"
            "informations button";
          grid-template-rows: auto auto 1fr;
        }

        h4 {
          font-size: 1.5rem;
          font-weight: 600;
          margin-bottom: 0.25rem;
        }

        p {
          font-size: 0.815rem;
          line-height: 1.3rem;
          margin-bottom: 0;
        }

        dl {
          dt {
            display: flex;
            align-items: center;
            gap: 1rem;
            margin-top: 1.5rem;

            img {
              width: 73px;
            }

            div {
              display: flex;
              flex-direction: column;

              h5 {
                font-size: 1rem;
              }

              p {
                font-size: 0.71rem;
                line-height: 1rem;
              }
            }
          }
        }
      }
    }

    @media screen and (min-width: 992px) {
      max-width: 960px;

      h1 {
        font-size: 1.5rem;
      }

      h2 {
        font-size: 2.4rem;
        width: 90%;
      }

      .subtitle {
        margin-bottom: 2rem;
        gap: 1.25rem;

        img {
          height: 73px;
        }

        p {
          line-height: 1.1;
          font-size: 2.1rem;
          width: 90%;
        }
      }

      a {
        font-size: 1.2rem;
      }

      .product {
        h3 {
          font-size: 3rem;
        }

        p {
          font-size: 2rem;
        }
      }

      .product-details {
        margin-bottom: 4rem;

        h4 {
          font-size: 1.85rem;
        }

        p {
          font-size: 0.95rem;
          line-height: 1.5rem;
        }

        &.no-title {
          dl {
            dt {
              p {
                font-size: 1.2rem;
              }
            }
          }
        }

        dl {
          dt {
            div {
              h5 {
                font-size: 1.3rem;
              }

              p {
                font-size: 1rem;
                line-height: 1.4rem;
              }
            }
          }
        }
      }
    }
  }
}
