#CadastrarPet {
  width: 100%;
  height: 100%;
  background-color: #f5f7fb;
}
#CadastrarPet #preLoader {
  height: 2rem;
  width: 100%;
  display: flex;
  justify-content: center;
}
#CadastrarPet #preLoader .lds-spinner {
  height: 1rem;
  width: 1rem;
}
#CadastrarPet #error__main {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: #b42446;
  font-size: 0.8rem;
  margin-bottom: 2rem;
}
#CadastrarPet #error__main #title {
  font-weight: 500;
  font-family: Helvetica, Arial;
}
#CadastrarPet #error__main #info {
  font-weight: 300;
  font-family: Helvetica, Arial;
  text-align: center;
}
#CadastrarPet #error__field {
  width: 100%;
  height: 100%;
  font-weight: 500;
  font-family: Helvetica, Arial;
  margin-top: 0.5rem;
  display: flex;
  /* justify-content: baseline; */
  color: #b42446;
  font-size: 0.8rem;
}
#CadastrarPet #cadastrarPet__container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  /* z-index: 1; */
}
#CadastrarPet #cadastrarPet__container form {
  background-color: #fff;
  border-radius: 5px;
  box-shadow: 0px 1px 4px #00000014;
  width: 437px;
  display: flex;
  position: relative;
  margin-bottom: 0.75rem;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
#CadastrarPet #row2 #container__input input {
  text-indent: 1rem;
}
#CadastrarPet #container__input input {
  width: 80%;
}
#CadastrarPet #cadastrarPet__container #row #container__input {
  width: 100%;
}
@media only screen and (max-width: 990px) {
  #CadastrarPet #cadastrarPet__container form {
    max-width: initial;
    position: static;
  }
}
#CadastrarPet #cadastrarPet__container #close {
  display: block;
  width: 1.5rem;
  height: 1.5rem;
  position: absolute;
  top: -10rem;
  right: -2rem;
  font-weight: 600;
  font-size: 1.25rem;
  color: #b42446;
  cursor: pointer;
}
@media only screen and (max-width: 990px) {
  #CadastrarPet #cadastrarPet__container #close {
    top: 0.5rem;
    right: 0.5rem;
  }
}
#CadastrarPet #cadastrarPet__container #title {
  width: 100%;
  margin: 4rem 0 0 0;
}
#CadastrarPet #cadastrarPet__container #title #main-title {
  display: flex;
  justify-content: center;
  text-align: center;
  margin: 0 0 1.875rem 0;
}
#CadastrarPet #cadastrarPet__container #title #main-title span {
  color: #8D2458;
  font-weight: 500;
  font-family: Helvetica, Arial;
  font-size: 2.313rem;
  letter-spacing: 0.03125rem;
}
/* @media only screen and (max-width: 990px) {
  #CadastrarPet #cadastrarPet__container #title #main-title span {
    font-size: 1.25rem;
  }
} */
#CadastrarPet #cadastrarPet__container #title #info {
  text-align: center;
}
#CadastrarPet #cadastrarPet__container #title #info span {
  font-weight: 300;
  font-family: Helvetica, Arial;
  font-size: 1rem;
  color: #0a0b0c;
}
@media only screen and (max-width: 990px) {
  #CadastrarPet #cadastrarPet__container #title #info span {
    font-size: 0.8rem;
  }
}
#CadastrarPet #cadastrarPet__container #row1 {
  /* border: 1px solid red; */
  margin-top: 1rem;
  display: flex;
  justify-content: space-between;
  width: 85%;
}
#CadastrarPet #cadastrarPet__container #row1 #container__input--radio {
  display: flex;
  margin-top: 1rem;
}
#CadastrarPet #cadastrarPet__container #row1 #content {
  /* border: 1px solid red; */
  width: 50%;
  font-size: 0.875rem;
}
#CadastrarPet #cadastrarPet__container #row2 {
  /* border: 1px solid red; */
  width: 90%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 2.5rem 0 1rem;
}
#CadastrarPet #cadastrarPet__container #row2 #content {
  display: flex;
}

#CadastrarPet #cadastrarPet__container #row2 #container__input {
  height: 2.45rem;
  width: 8.125rem;
  margin: 0.625rem;
}
#CadastrarPet #cadastrarPet__container #row2 #container__select {
  width: 7.125rem;
  margin: 0.625rem;
}
#CadastrarPet #cadastrarPet__container #row2 .weight #container__select {
  width: 6.875rem;
}
#CadastrarPet #cadastrarPet__container #row2 #content #content-option {
  font-size: 0.875rem;
  margin-left: 20px;
}
#CadastrarPet #cadastrarPet__container #row1 #input--radio__content {
  font-size: 0.875rem;
  margin-right: 8px;
}
#CadastrarPet #cadastrarPet__container #row1 #input--radio__content input[type=radio],
#CadastrarPet #cadastrarPet__container #row3 #input--radio__content input[type=radio] {
  display: none;
}
#CadastrarPet #cadastrarPet__container #row1 #input--radio__content label {
  font-weight: 500;
  font-family: Helvetica,Arial;
  font-size: .75rem;
  color: #0a0b0c;
  white-space: nowrap;
}
#CadastrarPet #cadastrarPet__container #row1 #input--radio__content label::before,
#CadastrarPet #cadastrarPet__container #row3 #input--radio__content label::before {
  content: " ";
  display: inline-block;
  position: relative;
  top: 0.3125rem;
  margin: 0 0.4rem 0 0;
  width: 1rem;
  height: 1rem;
  border-radius: 0.6875rem;
  border: 0.0625rem solid #e3e6eb;
  background-color: #fff;
}
#CadastrarPet #cadastrarPet__container #row1 #input--radio__content input[type=radio]:checked+label:after,
#CadastrarPet #cadastrarPet__container #row3 #input--radio__content input[type=radio]:checked+label:after {
  content: " ";
  border-radius: 0.6875rem;
  width: 0.5rem;
  height: 0.5rem;
  position: absolute;
  margin-top: -0.75rem;
  margin-left: 0.625rem;
  display: block;
  background: #B32346
}
#CadastrarPet #cadastrarPet__container #row {
  width: 100%;
  display: flex;
  flex-direction: column;
  /* align-items: center; */
  justify-content: center;
  align-items: center;
  margin: 2rem 0 1rem;
}
#CadastrarPet #cadastrarPet__container hr {
  width: 100%;
  border: 1px solid #E9EAEA;
}
#CadastrarPet #cadastrarPet__container #row #content #name-pet {
  width: 100%;
}
#CadastrarPet #cadastrarPet__container #row #content #btn-remove-pet{
  position: relative;
}
#CadastrarPet #cadastrarPet__container #row #content #btn-remove-pet button {
  position: absolute;
  cursor: pointer;
  background-color: inherit;
  border: none;
  margin: 0 auto;
  left: -0.6rem;
  right: 0;
  top: -5.3rem;
  bottom: 0;
}
#CadastrarPet #cadastrarPet__container #row #choose-a-specie {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
#CadastrarPet #cadastrarPet__container #row #choose-a-specie span {
  font-weight: 300;
  font-family: Helvetica, Arial;
  font-size: 0.75rem;
}
#CadastrarPet #cadastrarPet__container #row #container__input--manual {
  margin-bottom: 1rem;
}
#CadastrarPet #cadastrarPet__container #row #container__input--manual div {
  margin-bottom: 1rem;
}
#CadastrarPet #cadastrarPet__container #row #content {
  align-items: center;
  display: flex;
  width: 85%;
  height: 100%;
  font-weight: 500;
  font-family: Helvetica, Arial;
  text-indent: 1rem;
  font-size: 1rem;
}
@media only screen and (max-width: 990px) {
  #CadastrarPet #cadastrarPet__container #row #content {
    font-size: 0.8rem;
  }
}
#CadastrarPet #cadastrarPet__container #row .two-radios {
  width: 45% !important;
  display: flex;
  /* justify-content: baseline; */
}
#CadastrarPet #cadastrarPet__container #row #container__input--radio {
  width: 100%;
  height: 100%;
  display: flex;
  position: relative;
  left: 0.625rem;
  flex-direction: row;
  /* justify-content: baseline; */
  margin-bottom: 1rem;
}
#CadastrarPet #cadastrarPet__container #row #container__input--radio #input--radio__content {
  width: 31%;
  height: 100%;
  position: relative;
  display: flex;
  /* justify-content: baseline; */
}
#CadastrarPet #cadastrarPet__container #row #container__input--radio #input--radio__content input[type=radio] {
  display: none;
}
#CadastrarPet #cadastrarPet__container #row #container__input--radio #input--radio__content label {
  font-weight: 500;
  font-family: Helvetica, Arial;
  font-size: 0.75rem;
  color: #0a0b0c;
  white-space: nowrap;
}
#CadastrarPet #cadastrarPet__container #row #container__input--radio #input--radio__content label:before {
  content: " ";
  display: inline-block;
  position: relative;
  top: 0.3125rem;
  margin: 0 0.4rem 0 0;
  width: 1.25rem;
  height: 1.25rem;
  border-radius: 0.6875rem;
  border: 0.0625rem solid #e3e6eb;
  background-color: #ffffff;
}
#CadastrarPet #cadastrarPet__container #row #container__input--radio #input--radio__content input[type=radio]:checked + label:after {
  content: " ";
  border-radius: 0.6875rem;
  width: 0.875rem;
  height: 0.875rem;
  position: absolute;
  top: 0.5625rem;
  left: 0.5625rem;
  display: block;
  background: #b42446;
}
#CadastrarPet #cadastrarPet__container #row #select-a-photo {
  /* width: 100%; */
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 0.6875rem;
  margin-right: 22px;
}
#CadastrarPet #cadastrarPet__container #row #select-a-photo #select-a-photo__container {
  box-sizing: border-box;
}
#CadastrarPet #cadastrarPet__container #row #select-a-photo #select-a-photo__container input {
  display: none;
}
#CadastrarPet #cadastrarPet__container #row #select-a-photo #select-a-photo__container #box-photo {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 4.625rem;
  height: 4.625rem;
  background-color: #ffffff;
  border-radius: 100%;
  border: 1px solid #C9C9C9;
  cursor: pointer;
}
#CadastrarPet #cadastrarPet__container #row #select-a-photo #select-a-photo__container #box-photo::after {
  content: "";
  position: absolute;
  background-image: url("../../images/plus-cadastrar-pet.svg");
  background-repeat: no-repeat;
  width: 2.063rem;
  height: 2.063rem;
  bottom: 0;
  right: -0.6rem;
}
#CadastrarPet #cadastrarPet__container #row #select-a-photo #select-a-photo__container #box-photo #box-photo--editedPhoto {
  margin: 0 auto;
  display: block;
  padding: 0;
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  height: 4rem;
  width: 4rem;
  border-radius: 100%;
}
#CadastrarPet #cadastrarPet__container #row #select-a-photo #select-a-photo__container #box-photo #box-photo--icon span {
  margin: 0 auto;
  display: block;
  padding: 0;
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  height: 4rem;
  width: 4rem;
  background-image: url("../../images/cadastrar-pet.svg");
}
#CadastrarPet #cadastrarPet__container #row #select-a-photo #select-a-photo__container #box-photo #box-photo--archive {
  font-weight: 500;
  font-family: Helvetica, Arial;
  margin-top: 1rem;
  text-decoration: underline;
}
#CadastrarPet #cadastrarPet__container #row #select-a-photo #photo--maxSize {
  margin-top: 2rem;
}
#CadastrarPet #cadastrarPet__container #row #select-a-photo #photo--maxSize span {
  font-weight: 300;
  font-family: Helvetica, Arial;
}
#CadastrarPet #cadastrarPet__container #row3 {
  /* border: 1px solid red; */
  width: 85%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  margin: 1rem 0 2rem;
}
#CadastrarPet #cadastrarPet__container #row3 #row3-content {
  display: flex;
  width: 100%;
  align-items: baseline;
}
#CadastrarPet #cadastrarPet__container #row3 span {
  align-items: center;
  display: flex;
  margin-bottom: 0.5rem;
}
#CadastrarPet #cadastrarPet__container #row3 #required {
  font-size: 0.8rem;
  color: rgba(101, 101, 101, 0.635);
}
#CadastrarPet #cadastrarPet__container #row3 #container__input--radio {
  /* border: 1px solid red; */
  width: 40%;
  display: flex;
  margin-left: 3rem;
}
#CadastrarPet #cadastrarPet__container #row3 #container__input--radio #input--radio__content {
  margin-right: 1rem;
}
#CadastrarPet #cadastrarPet__container #row3 #use-date {
  width: 100%;
  margin: 1rem 0;
}
#CadastrarPet #cadastrarPet__container #row3 #use-date span {
  font-size: 0.875rem;
  text-indent: 0.8rem;
}
#CadastrarPet #cadastrarPet__container #btn-add-pet {
  display: flex;
  justify-content: center;
  width: 437px;
}
#CadastrarPet #cadastrarPet__container #btn-add-pet #button-add-pet {
  position: relative;
  background-color: #E0E1E2;
  border: 0px;
  border-radius: 1.5rem;
  color: #393939;
  cursor: pointer;
  width: 100%;
  font-size: 0.9rem;
  font-weight: 500;
  font-family: Helvetica, Arial;
  letter-spacing: 0.03125rem;
  height: 2.5rem;
}
#CadastrarPet #cadastrarPet__container #btn-add-pet #button-add-pet::before {
  content: "";
  position: absolute;
  background-image: url("../../images/plus-adicionar-pet.svg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  width: 0.796rem;
  height: 0.796rem;
  top: 0.8rem;
  left: 8rem;
}
#CadastrarPet #cadastrarPet__container #btn-submit {
  display: flex;
  justify-content: space-between;
  width: 437px;
  margin: 1.5rem 0 4rem;
}
#CadastrarPet #cadastrarPet__container #btn-submit #btn-voltar {
  position: relative;
  width: 4.688rem;
  height: 2.438rem;
  background-color: #E0E1E2;
  border-radius: 1.5rem;
}
#CadastrarPet #cadastrarPet__container #btn-submit #btn-voltar::after {
  content: "";
  position: absolute;
  background-image: url("../../images/left-arrow.svg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  width: 1rem;
  height: 1rem;
  bottom: 0;
  top: 0.7rem;
  left: 0;
  right: 0;
  margin: 0 auto;
}
#CadastrarPet #cadastrarPet__container #btn-submit #btn-cadastrarPet {
  position: relative;
  height: 2.438rem;
  background-color: #b42446;
  color: #ffffff;
  font-weight: 500;
  font-family: Helvetica, Arial;
  border-radius: 1.5rem;
  border: 0px;
  width: 11.813rem;
  letter-spacing: 0.03125rem;
  cursor: pointer;
  /* margin-bottom: 2rem; */
  font-size: 0.8rem;
  text-align: left;
  text-indent: 1rem;
}
#CadastrarPet #cadastrarPet__container #btn-submit #btn-cadastrarPet::after {
  content: "";
  position: absolute;
  background-image: url('../../images/btn-edit-salvar.svg');
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  width: 6px;
  height: 25px;
  bottom: 0;
  top: 0.4rem;
  right: 1rem;
}
#CadastrarPet #cadastrarPet__container #row #btn-cadastrarPet:focus {
  outline: none;
}
#CadastrarPet #cadastrarPet__container #row #btn-cadastrarPet:hover {
  background-color: #a12e4a;
}
#ModalError-CadastrarPet #Modal #Modal__container {
  width: 26.375rem;
  height: 12.75rem;
  background-color: #FAE8ED;
  border: 1px solid #6C757D;
}
#ModalError-CadastrarPet #Modal #Modal__container #Modal__container--close {
  position: relative;
  align-items: center;
  border-bottom: 1px solid #D9D9D9;
  background: #F2E1E6;
  padding: 0.313rem 0.938rem;
  height: 3rem;
  margin: 0;
  justify-content: space-between;
  border-radius: 5px;
}
#ModalError-CadastrarPet #Modal #Modal__container #Modal__container--close #mdClose::after {
  content: "";
  cursor: pointer;
  position: absolute;
  background-image: url('../../images/icon-remove.svg');
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  width: 1.5rem;
  height: 1.5rem;
  top: 0.6rem;
  right: 1.1rem;
}
#ModalError-CadastrarPet #Modal #Modal__container #Modal__container--desc {
  align-items: flex-start;
  padding: 15px;
  justify-content: center;
  text-align: left;
  height: 100%;
  font-weight: bold;
}
#ModalError-CadastrarPet #Modal #Modal__container #Modal__container--desc p {
  font-weight: normal;
  margin: 0.5rem 0;
}
#ModalError-CadastrarPet #Modal #Modal__container #Modal__container--save {
  display: none;
}
#CadastrarPet #cadastrarPet__container #container__select svg {
  color: #6F6F6F;
  width: 1.2rem;
}

@media only screen and (max-width: 426px) {
  #CadastrarPet #cadastrarPet__container #row #content #name-pet {
    width: 90%;
  }
  #CadastrarPet #cadastrarPet__container form,
  #CadastrarPet #cadastrarPet__container #btn-submit,
  #CadastrarPet #cadastrarPet__container #btn-add-pet {
    width: 330px;
  }
  #CadastrarPet #cadastrarPet__container #row #content {
    width: 95%;
    flex-direction: column;
    justify-content: center;
  }
  #CadastrarPet #cadastrarPet__container #row2 #content{
    flex-direction: column;
    width: 100%;
  }
  #CadastrarPet #cadastrarPet__container #row1 {
    flex-direction: column;
    margin: 0;
  }
  #CadastrarPet #cadastrarPet__container #row1 #content {
    width: 90%;
    margin: 1rem 0;
  }
  #CadastrarPet #cadastrarPet__container #row1 #container__input--radio {
    margin: 0;
  }
  #CadastrarPet #cadastrarPet__container #row1 #input--radio__content {
    width: 65px;
  }
  #CadastrarPet #cadastrarPet__container #row2 #container__input,
  #CadastrarPet #cadastrarPet__container #row2 #container__select,
  #CadastrarPet #cadastrarPet__container #row1 #content #content-mobile{
    width: 95% !important;
    margin: 0.625rem 0;
  }
  #CadastrarPet #cadastrarPet__container #row3 #container__input--radio,
  #CadastrarPet #cadastrarPet__container #row3 #container__input--radio #input--radio__content,
  #CadastrarPet #cadastrarPet__container #row1 #input--radio__content label {
    margin: 0;
  }

  #ModalError-CadastrarPet #Modal #Modal__container {
    width: 20.375rem;
    height: 10.75rem;
  }

  #CadastrarPet #cadastrarPet__container #btn-add-pet #button-add-pet::before {
    left: 4.5rem;
  }
  #CadastrarPet #cadastrarPet__container #row1 #input--radio__content input[type=radio]:checked+label:after {
    margin-top: -0.75rem !important;
    margin-left: 0.3rem !important;
  }
  #CadastrarPet #cadastrarPet__container #row3 #row3-content {
    flex-direction: column;
    align-items: center;
  }
  #CadastrarPet #cadastrarPet__container #row3 #required {
    text-align: center;
    margin-top: 1rem;
  }
  #CadastrarPet #cadastrarPet__container #row3 span {
    justify-content: center;
  }
  #CadastrarPet #cadastrarPet__container #row3 #container__input--radio #input--radio__content {
    align-items: center;
    margin: 0 1rem;
  }
  #CadastrarPet #cadastrarPet__container #row3 #container__input--radio {
    justify-content: center;
    width: 100%;
  }
  #CadastrarPet #cadastrarPet__container #row3 #use-date {
    display: flex;
    align-items: center;
    flex-direction: column;
  }
  #CadastrarPet #cadastrarPet__container #row3 #use-date span {
    margin-left: 1.5rem;
    text-indent: 0;
  }
  #CadastrarPet #cadastrarPet__container #row2 {
    margin: 0.5rem 0;
  }
  #ModalError-CadastrarPet #Modal #Modal__container #Modal__container--close #mdClose::after {
    top: 0.35rem;
  }
  #CadastrarPet #cadastrarPet__container #row #content #btn-remove-pet button {
    left: 7.5rem;
    top: -16rem;
  }
}

@media only screen and (max-width: 320px) {
  #CadastrarPet #cadastrarPet__container form,
  #CadastrarPet #cadastrarPet__container #btn-submit,
  #CadastrarPet #cadastrarPet__container #btn-add-pet {
    width: 290px;
  }
  #CadastrarPet #cadastrarPet__container #row2 #container__input,
  #CadastrarPet #cadastrarPet__container #row2 #container__select {
    width: 13rem;
  }

  #CadastrarPet #cadastrarPet__container #row1 #content {
    width: 100%;
  }
  #CadastrarPet #cadastrarPet__container #title #main-title span {
    font-size: 1.7rem;
  }

  #ModalError-CadastrarPet #Modal #Modal__container {
    width: 16.575rem;
    height: 10.75rem;
  }
  #CadastrarPet #cadastrarPet__container #btn-submit #btn-cadastrarPet {
    width: 8.813rem;
    text-indent: 0.2rem;
  }
  #CadastrarPet #cadastrarPet__container #btn-submit #btn-cadastrarPet::after {
    top: 0.5rem;
  }
  #CadastrarPet #cadastrarPet__container #btn-add-pet #button-add-pet::before {
    left: 2rem;
  }
  #CadastrarPet #cadastrarPet__container #row #content #btn-remove-pet button {
    left: 6.5rem;
  }
}
/*# sourceMappingURL=CadastrarPetPet.css.map */