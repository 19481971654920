#CadastrarPet {
  width: 100%;
  height: 100%;
  background-color: #f5f7fb;
}

#CadastrarPet #preLoader {
  height: 2rem;
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}

#CadastrarPet #preLoader .lds-spinner {
  height: 1rem;
  width: 1rem;
}

#CadastrarPet #error__main {
  width: 100%;
  height: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  color: #b42446;
  font-size: 0.8rem;
  margin-bottom: 2rem;
}

#CadastrarPet #error__main #title {
  font-weight: 500;
  font-family: Helvetica, Arial;
}

#CadastrarPet #error__main #info {
  font-weight: 300;
  font-family: Helvetica, Arial;
  text-align: center;
}

#CadastrarPet #error__field {
  width: 100%;
  height: 100%;
  font-weight: 500;
  font-family: Helvetica, Arial;
  margin-top: 0.5rem;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  /* justify-content: baseline; */
  color: #b42446;
  font-size: 0.8rem;
}

#CadastrarPet #cadastrar__container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  z-index: 1;
}

#CadastrarPet #cadastrar__container #title {
  width: 100%;
  margin: 2rem 0 2rem 0;
}

#CadastrarPet #cadastrar__container #title #main-title {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  text-align: center;
  margin: 0 0 1.875rem 0;
}

#CadastrarPet #cadastrar__container #title #main-title span {
  background: linear-gradient(-101deg, #482774 20%, #e11832 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: rgba(0, 0, 0, 0.0001);
  font-weight: 500;
  font-family: Helvetica, Arial;
  font-size: 1.8rem;
  letter-spacing: 0.03125rem;
}

@media only screen and (max-width: 990px) {
  #CadastrarPet #cadastrar__container #title #main-title span {
    font-size: 1.25rem;
  }
}

#CadastrarPet #cadastrar__container #title #info {
  text-align: center;
}

#CadastrarPet #cadastrar__container #title #info span {
  font-weight: 300;
  font-family: Helvetica, Arial;
  font-size: 1rem;
  color: #0a0b0c;
}

@media only screen and (max-width: 990px) {
  #CadastrarPet #cadastrar__container #title #info span {
    font-size: 0.8rem;
  }
}

#CadastrarPet #cadastrarPet__container #row #container__input {
  margin-top: 0.5rem;
}

#CadastrarPet #cadastrar__container #row {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  /* align-items: center; */
  justify-content: center;
  align-items: center;
  margin: 2.5rem 0 1rem;
}

#CadastrarPet #cadastrar__container #row #choose-a-specie {
  width: 100%;
  height: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}

#CadastrarPet #cadastrar__container #row #choose-a-specie span {
  font-weight: 300;
  font-family: Helvetica, Arial;
  font-size: 0.75rem;
}

#CadastrarPet #cadastrar__container #row #container__input--manual {
  margin-bottom: 1rem;
}

#CadastrarPet #cadastrar__container #row #container__input--manual div {
  margin-bottom: 1rem;
}

#CadastrarPet #cadastrar__container #row #content {
  width: 100%;
  height: 100%;
  font-weight: 500;
  font-family: Helvetica, Arial;
  text-indent: 1rem;
  font-size: 1rem;
  margin-bottom: 0.5rem;
}

#Cadastrar #cadastrar__container #content-option {
  margin-left: 20px !important;
}

@media only screen and (max-width: 990px) {
  #CadastrarPet #cadastrar__container #row #content {
    font-size: 0.8rem;
  }
}

#CadastrarPet #cadastrar__container #row .two-radios {
  width: 45% !important;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  /* justify-content: baseline; */
}

#CadastrarPet #cadastrar__container #row #container__input--radio {
  width: 100%;
  height: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  position: relative;
  left: 0.625rem;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  /* justify-content: baseline; */
  margin-bottom: 1rem;
}

#CadastrarPet #cadastrar__container #row #container__input--radio #input--radio__content {
  width: 31%;
  height: 100%;
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  /* justify-content: baseline; */
}

#CadastrarPet #cadastrar__container #row #container__input--radio #input--radio__content input[type=radio] {
  display: none;
}

#CadastrarPet #cadastrar__container #row #container__input--radio #input--radio__content label {
  font-weight: 500;
  font-family: Helvetica, Arial;
  font-size: 0.75rem;
  color: #0a0b0c;
  white-space: nowrap;
}

#CadastrarPet #cadastrar__container #row #container__input--radio #input--radio__content label:before {
  content: " ";
  display: inline-block;
  position: relative;
  top: 0.3125rem;
  margin: 0 0.4rem 0 0;
  width: 1.25rem;
  height: 1.25rem;
  border-radius: 0.6875rem;
  border: 0.0625rem solid #e3e6eb;
  background-color: #ffffff;
}

#CadastrarPet #cadastrar__container #row #container__input--radio #input--radio__content input[type=radio]:checked + label:after {
  content: " ";
  border-radius: 0.6875rem;
  width: 0.875rem;
  height: 0.875rem;
  position: absolute;
  top: 0.5625rem;
  left: 0.5625rem;
  display: block;
  background: #b42446;
}

#CadastrarPet #cadastrar__container #row #select-a-photo {
  width: 100%;
  height: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  font-size: 0.6875rem;
}

#CadastrarPet #cadastrar__container #row #select-a-photo #select-a-photo__container {
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
}

#CadastrarPet #cadastrar__container #row #select-a-photo #select-a-photo__container input {
  display: none;
}

#CadastrarPet #cadastrar__container #row #select-a-photo #select-a-photo__container #box-photo {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  width: 8.125rem;
  height: 8.125rem;
  background-color: #ffffff;
  border-radius: 5px;
  border: 1px solid #dfe2e8;
  cursor: pointer;
}

#CadastrarPet #cadastrar__container #row #select-a-photo #select-a-photo__container #box-photo #box-photo--editedPhoto {
  height: 100%;
  width: 100%;
  margin: 0 auto;
  display: block;
  padding: 0;
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  height: 8.125rem;
  width: 8.125rem;
}

#CadastrarPet #cadastrar__container #row #select-a-photo #select-a-photo__container #box-photo #box-photo--icon span {
  height: 100%;
  width: 100%;
  margin: 0 auto;
  display: block;
  padding: 0;
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  height: 2.8125rem;
  width: 2.8125rem;
  background-image: url("../../images/camera.png");
}

#CadastrarPet #cadastrar__container #row #select-a-photo #select-a-photo__container #box-photo #box-photo--archive {
  font-weight: 500;
  font-family: Helvetica, Arial;
  margin-top: 1rem;
  text-decoration: underline;
}

#CadastrarPet #cadastrar__container #row #select-a-photo #photo--maxSize {
  margin-top: 2rem;
}

#CadastrarPet #cadastrar__container #row #select-a-photo #photo--maxSize span {
  font-weight: 300;
  font-family: Helvetica, Arial;
}

#CadastrarPet #cadastrarPet__container #btn-submit-editar {
  display: flex;
  justify-content: space-between;
  width: 437px;
}

#CadastrarPet #cadastrarPet__container #btn-submit-editar #btn-voltar {
  position: relative;
  width: 70px;
  padding: 8px;
  height: 25px;
  background-color: #E0E1E2;
  border-radius: 1.5rem;
}

#CadastrarPet #cadastrarPet__container #btn-submit-editar #btn-voltar::after {
  content: "";
  position: absolute;
  background-image: url("../../images/left-arrow.svg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  width: 16px;
  height: 30px;
  bottom: 0;
  top: 0.4rem;
  left: 0;
  right: 0;
  margin: 0 auto;
}

#CadastrarPet #cadastrarPet__container #btn-submit-editar #btn-edit-salvar {
  position: relative;
  height: 2.8125rem;
  background-color: #b42446;
  color: #ffffff;
  font-size: 0.9rem;
  font-weight: 500;
  font-family: Helvetica, Arial;
  border-radius: 1.5rem;
  border: 0px;
  width: 45%;
  margin-bottom: 2.5rem;
  letter-spacing: 0.03125rem;
  cursor: pointer;
  height: 2.5rem;
  margin-bottom: 2rem;
  font-size: 0.8rem;
  text-align: left;
  text-indent: 1rem;
}

#CadastrarPet #cadastrarPet__container #btn-submit-editar #btn-edit-salvar::after {
  content: "";
  position: absolute;
  background-image: url("../../images/btn-edit-salvar.svg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  width: 6px;
  height: 25px;
  bottom: 0;
  top: 0.4rem;
  right: 1rem;
}

@media only screen and (max-width: 426px) {
  #CadastrarPet #cadastrarPet__container #btn-submit-editar {
    width: 330px;
  }
  #Cadastrar #row2 #container__input input {
    text-align: start;
  }
  #Cadastrar #cadastrar__container #row2 {
    flex-direction: column;
  }
}

@media only screen and (max-width: 320px) {
  #CadastrarPet #cadastrarPet__container #btn-submit-editar {
    width: 250px;
  }
}
/*# sourceMappingURL=CadastrarPet.css.map */