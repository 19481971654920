@import "../../utils/styles/_styles.scss";

#Modal {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow: hidden;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 2;
  @include flexColumnCenter;

  .alert-enter {
    opacity: 0;
    transform: scale(0.9);
  }

  .alert-enter-active {
    opacity: 1;
    transform: translateX(0);
    transition: opacity 300ms, transform 300ms;
  }
  .alert-exit {
    opacity: 1;
  }
  .alert-exit-active {
    opacity: 0;
    transform: scale(0.9);
    transition: opacity 300ms, transform 300ms;
  }

  #Modal__container {
    display: flex;
    flex-direction: column;
    height: auto;
    width: 22.5rem;
    background-color: #f5f7fb;
    border-radius: 5px;

    #Modal__container--close {
      display: flex;
      justify-content: flex-end;
      margin-bottom: 2rem;

      svg {
        color: #b42446;
        cursor: pointer;
        height: 2rem;
        width: 2rem;
      }
    }

    #Modal__container--desc {
      @include flexColumnCenter;
      @include helvetica;
      text-align: center;
      font-size: 1rem;
      padding: 1rem;
    }

    #Modal__container--save {
      @include flexRowCenter;
      margin-top: 1rem;
      button {
        @include defaultBtn;
      }
    }
  }
}
