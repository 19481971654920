@mixin helvetica {
  font-family: Helvetica, Arial;
}

@mixin helveticaMedium {
  font-weight: 500;
  font-family: Helvetica, Arial;
}

@mixin helveticaLight {
  font-weight: 300;
  font-family: Helvetica, Arial;
}

@mixin helveticaBold {
  font-weight: 600;
  font-family: Helvetica, Arial;
}
