#RankingMomentosBravecto .RankingMomentos-container {
  display: flex;
  justify-content: center;
  width: 100%;
}

#RankingMomentosBravecto .RankingMomentosBravecto-container {
  display: flex;
  flex-direction: column;
  position: relative;
}

#RankingMomentosBravecto .RankingMomentosBravecto-container .banner-mob {
  display: none;
}

@media only screen and (max-width: 768px) {
  #RankingMomentosBravecto .RankingMomentosBravecto-container .banner-mob {
    display: block;
  }

  #RankingMomentosBravecto .RankingMomentosBravecto-container .banner-desk {
    display: none;
  }
}

#RankingMomentosBravecto .RankingMomentos-sub-container {
  align-items: center;
  justify-content: center;
  display: flex;
  flex-direction: column;
  width: 1040px;
  height: 150px;
  margin-top: 10rem;
}

#RankingMomentosBravecto .RankingMomentos-sub-container h1 {
  margin: 0;
  color: #429692;
  font-size: 3.125rem;
  text-shadow: 0px 3px 6px #00000029;
}

#RankingMomentosBravecto .RankingMomentos-sub-container h2 {
  color: #6F308D;
  text-align: center;
  text-shadow: 0px 3px 6px #00000029;
  font-size: 1.938rem;
  margin: 0;
}

#RankingMomentosBravecto .RankingMomentosBravecto-container-nf {
  align-items: center;
  background-color: #DBDBDB;
  display: flex;
  justify-content: center;
  width: 100%;
  height: 195px;
}

.RankingMomentosBravecto-container-nf .RankingMomentosBravecto-sub-container-nf {
  align-items: center;
  justify-content: center;
  display: flex;
  flex-direction: column;
  max-width: 100%;
  width: 1040px;
}

#RankingMomentosBravecto .RankingMomentosBravecto-sub-container-nf .total-nfs {
  display: flex;
  justify-content: center;
  width: 100%;
}

#RankingMomentosBravecto .RankingMomentosBravecto-sub-container-nf .total-nf {
  align-items: center;
  display: flex;
  justify-content: center;
  flex-direction: column;
  width: 300px;
  text-align: center;
  margin: 0 5rem;
}

#RankingMomentosBravecto .RankingMomentosBravecto-sub-container-nf .total-nf p {
  font-weight: bold;
  font-size: 3.25rem;
  color: #429692;
  margin: 0;
}

#RankingMomentosBravecto .RankingMomentosBravecto-sub-container-nf .total-nf span {
  margin: 0;
  font-size: 1.125rem;
  font-weight: bold;
  color: #8452A3;
}

#RankingMomentosBravecto .RankingMomentos-sub-container .RankingMomentos-ganhadores-container {
  /* border: 1px solid red; */
  align-items: center;
  display: flex;
  margin-top: 10rem;
  /* flex-wrap: wrap; */
}

#RankingMomentosBravecto .RankingMomentos-sub-container .RankingMomentos-ganhadores-container .RankingMomentos-ganhadores {
  position: relative;
  align-items: center;
  display: flex;
  flex-direction: column;
  margin: 0 3.5rem;
  /* order: 2; */
}

#RankingMomentosBravecto .RankingMomentos-sub-container .RankingMomentos-ganhadores-container .RankingMomentos-ganhadores h2 {
  color: #28A7DE;
  position: relative;
  font-size: 1.5rem;
  top: -3rem;
  margin: 0;
}

#RankingMomentosBravecto .RankingMomentos-sub-container .RankingMomentos-ganhadores-container .RankingMomentos-ganhadores.primeiroLugar h2 {
  font-size: 1.875rem !important;
}

#RankingMomentosBravecto .RankingMomentos-sub-container .RankingMomentos-ganhadores-container .RankingMomentos-ganhadores p {
  color: #384148;
  position: relative;
  font-size: 2.75rem;
  font-weight: bold;
  margin: 0;
  top: -1rem;
}

#RankingMomentosBravecto .RankingMomentos-sub-container .RankingMomentos-ganhadores-container .RankingMomentos-ganhadores span {
  color: #384148;
  position: relative;
  top: -1.4rem;
  font-size: 1.688rem;
  font-weight: bold;
  margin: 0;
}

#RankingMomentosBravecto .RankingMomentos-sub-container .RankingMomentos-ganhadores-container .RankingMomentos-ganhadores::after {
  content: "";
  position: absolute;
  background-image: url("../../../images/PromocaoMomentosBravecto/coroa.svg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  width: 180px;
  height: 157px;
}

#RankingMomentosBravecto .RankingMomentos-sub-container .RankingMomentos-ganhadores-container .RankingMomentos-ganhadores.primeiroLugar::after {
  width: 244px;
  height: 213px;
}

#RankingMomentosBravecto .RankingMomentos-sub-container .RankingMomentos-ganhadores-container .primeiroLugar p {
  font-size: 3.75rem;
}

#RankingMomentosBravecto .RankingMomentos-sub-container .RankingMomentos-ganhadores-container .primeiroLugar span {
  font-size: 2.375rem;
}

#RankingMomentosBravecto .RankingMomentos-sub-container .RankingMomentos-ganhadores-container .primeiroLugar {
  position: relative;
  top: -5.4rem;
  order: 2;
  width: 228px;
}

#RankingMomentosBravecto .RankingMomentos-sub-container .RankingMomentos-ganhadores-container .segundoLugar {
  order: 1;
}

#RankingMomentosBravecto .RankingMomentos-sub-container .RankingMomentos-ganhadores-container .terceiroLugar {
  order: 3;
}

#RankingMomentosBravecto .RankingMomentos-sub-container .RankingMomentos-ganhadores-container .primeiroLugar
.RankingMomentos-ganhadores-infos p {
  font-size: 1.75rem !important;
  margin-top: 1.5rem !important;
}

#RankingMomentosBravecto .RankingMomentos-sub-container .RankingMomentos-ganhadores-container .primeiroLugar
.RankingMomentos-ganhadores-infos span {
  font-size: 1.125rem !important;
}

#RankingMomentosBravecto .RankingMomentos-sub-container .RankingMomentos-ganhadores-container .primeiroLugar
.RankingMomentos-ganhadores-infos .valor-premio {
  font-size: 1.75rem !important;
  height: 48px !important;
  width: 14.25rem !important;
}

#RankingMomentosBravecto .RankingMomentos-sub-container .RankingMomentos-ganhadores-container .RankingMomentos-ganhadores
.RankingMomentos-ganhadores-infos {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin-top: 3rem;
  width: 176px;
}

#RankingMomentosBravecto .RankingMomentos-sub-container .RankingMomentos-ganhadores-container .RankingMomentos-ganhadores
.RankingMomentos-ganhadores-infos p {
  align-items: center;
  display: flex;
  font-size: 1.375rem;
  margin: 0.5rem 0;
  height: 110px;
  font-size: 1.375rem;
  margin: 0.5rem 0;
}

#RankingMomentosBravecto .RankingMomentos-sub-container .RankingMomentos-ganhadores-container .RankingMomentos-ganhadores
.RankingMomentos-ganhadores-infos span {
  font-size: 1rem;
  color: #BCBCBC;
}

#RankingMomentosBravecto .RankingMomentos-sub-container .RankingMomentos-ganhadores-container .RankingMomentos-ganhadores
.RankingMomentos-ganhadores-infos .valor-premio {
  align-items: center;
  background-color: #D02D59;
  border-radius: 30px;
  height: 2.5rem;
  color: #fff;
  display: flex;
  justify-content: center;
  margin-top: 0.7rem;
  width: 11rem;
  font-size: 1.375rem;
}

#RankingMomentosBravecto .RankingMomentos-classificacao-container {
  flex-direction: column;
  width: 90%;
  max-width: 1080px;
  margin-bottom: 3rem;
}

#RankingMomentosBravecto .RankingMomentos-classificacao-container a {
  color: #6F308D;
  font-weight: 700;
  margin: 1rem;
}

#RankingMomentosBravecto .RankingMomentos-classificacao-container .icon-export {
  display: flex;
  justify-content: center;
}

#RankingMomentosBravecto .RankingMomentos-classificacao-container .icon-export img {
  width: 24px;
  height: 24px;
}

#RankingMomentosBravecto .RankingMomentos-sub-container .RankingMomentos-inputSearch,
#RankingMomentosBravecto .RankingMomentos-classificacao-container .RankingMomentos-inputSearch {
  margin-top: 2rem;
  position: relative;
  display: flex;
  justify-content: space-between;
}

#RankingMomentosBravecto .RankingMomentos-classificacao-container .RankingMomentos-inputSearch .total-nf-cadastradas {
  border: 2px solid #9066AF;
  border-radius: 30px;
  height: 36px;
  padding: 0 10px;
  display: flex;
  align-items: center;
}

#RankingMomentosBravecto .RankingMomentos-classificacao-container .RankingMomentos-inputSearch .total-nf-cadastradas p {
  font-weight: bold;
  color: #8452A3;
}

#RankingMomentosBravecto .RankingMomentos-classificacao-container .RankingMomentos-inputSearch .total-nf-cadastradas span {
  font-weight: bold;
  margin-left: 0.5rem;
  color: #D02D59;
}

#RankingMomentosBravecto .RankingMomentos-sub-container #container__input,
#RankingMomentosBravecto .RankingMomentos-classificacao-container #container__input {
  position: relative;
  border: 2px solid #9066AF;
  width: 18.5rem;
  text-indent: 1.5rem;
}

#RankingMomentosBravecto .RankingMomentos-sub-container #container__input input,
#RankingMomentosBravecto .RankingMomentos-classificacao-container #container__input input {
  width: 90%;
  text-indent: 1rem;
}

#RankingMomentosBravecto .RankingMomentos-sub-container .RankingMomentos-inputSearch span::after,
#RankingMomentosBravecto .RankingMomentos-classificacao-container .RankingMomentos-inputSearch .inputSearch span::after {
  content: "";
  cursor: pointer;
  position: absolute;
  background: url('../../../images/PromocaoMomentosBravecto/lupaSearch.svg') no-repeat center;
  width: 22px;
  height: 22px;
  margin: 0.65rem 0 0 1rem;
  z-index: 99;
}

#RankingMomentosBravecto .RankingMomentos-classificacao-container .Ranking-classificacao-sub-container {
  justify-content: space-between;
  display: flex;
}

#RankingMomentosBravecto .RankingMomentos-classificacao-container .Ranking-classificacao1,
#RankingMomentosBravecto .RankingMomentos-classificacao-container .Ranking-classificacao2,
#RankingMomentosBravecto .RankingMomentos-classificacao-container .Ranking-classificacao3,
#RankingMomentosBravecto .RankingMomentos-classificacao-container .Ranking-loja .Ranking-valores1,
#RankingMomentosBravecto .RankingMomentos-classificacao-container .Ranking-loja .Ranking-valores2 {
  display: flex;
  align-items: center;
}

#RankingMomentosBravecto .RankingMomentos-classificacao-container .Ranking-classificacao1 span {
  color: #41A1DA;
  font-weight: bold;
  margin-right: 20px;
}

#RankingMomentosBravecto .RankingMomentos-classificacao-container .Ranking-classificacao1 p {
  color: #384148;
  font-weight: bold;
  margin-right: 18px;
}

#RankingMomentosBravecto .RankingMomentos-classificacao-container .Ranking-classificacao2,
#RankingMomentosBravecto .RankingMomentos-classificacao-container .Ranking-classificacao3 {
  color: #384148;
  font-weight: bold;
}

#RankingMomentosBravecto .RankingMomentos-classificacao-container .Ranking-classificacao2 #uf,
#RankingMomentosBravecto .RankingMomentos-classificacao-container .Ranking-classificacao2 #dist {
  margin-right: 45px;
}

#RankingMomentosBravecto .RankingMomentos-classificacao-container .Ranking-classificacao2 #produtos {
  margin-right: 15px;
}

#RankingMomentosBravecto .RankingMomentos-classificacao-container .Ranking-classificacao2 #notas {
  /* margin-right: 15px; */
}

#RankingMomentosBravecto .RankingMomentos-classificacao-container .Ranking-classificacao3 {
  /* margin-left: 46px; */
}

#RankingMomentosBravecto .RankingMomentos-classificacao-container .Ranking-classificacao3 #dist {
  margin-left: 50px;
}

#RankingMomentosBravecto .RankingMomentos-classificacao-container .Ranking-loja.topFive {
  background: transparent linear-gradient(90deg, #06AEA3 0%, #6A2788 100%) 0% 0% no-repeat padding-box;
}

#RankingMomentosBravecto .RankingMomentos-classificacao-container .Ranking-loja {
  background: transparent linear-gradient(90deg, #9066AF 0%, #6A2788 100%) 0% 0% no-repeat padding-box;
  border-radius: 30px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  min-width: 1030px;
  height: 60px;
  margin-bottom: 20px;
}

#RankingMomentosBravecto .RankingMomentos-classificacao-container .Ranking-loja .Ranking-valores1 span {
  position: relative;
  background-color: #58347A;
  border-radius: 30px;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: bold;
  width: 52px;
  height: 52px;
  margin: 0 0.3rem
}

#RankingMomentosBravecto .RankingMomentos-classificacao-container .Ranking-loja.topFive .Ranking-valores1 span {
  background-color: #06AEA3;
}

#RankingMomentosBravecto .RankingMomentos-classificacao-container .Ranking-loja.topFive .Ranking-valores1 span::after {
  border: 2px solid white;
}

#RankingMomentosBravecto .RankingMomentos-classificacao-container .Ranking-loja .Ranking-valores1 span::after {
  content: "";
  position: absolute;
  border: 2px solid #58347A;
  border-radius: 30px;
  width: 56px;
  height: 56px;
}

#RankingMomentosBravecto .RankingMomentos-classificacao-container .Ranking-loja .Ranking-valores1 p {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-left: 18px;
  color: #fff;
  font-weight: bold;
}

#RankingMomentosBravecto .RankingMomentos-classificacao-container .Ranking-loja .Ranking-valores2 {
  color: #fff;
  font-weight: bold;
}

#RankingMomentosBravecto .RankingMomentos-classificacao-container .Ranking-loja .Ranking-valores2 #uf {
  margin-right: 18px;
}

#RankingMomentosBravecto .RankingMomentos-classificacao-container .Ranking-loja .Ranking-valores2 #dist {
  width: 15em;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-left: 25px;
}

#RankingMomentosBravecto .RankingMomentos-classificacao-container .Ranking-loja .Ranking-valores2 #produtos {
  margin-right: 50px;
}

#RankingMomentosBravecto .RankingMomentos-classificacao-container .Ranking-loja .Ranking-valores2 #notas {
  align-items: center;
  background-color: #06aea3;
  box-shadow: 0px 0px 16px #BCBCBC42;
  border-radius: 30px;
  color: #fff;
  display: flex;
  justify-content: center;
  width: 120px;
  height: 60px;
}

.zeroResult {
  text-align: center;
  color: #D02D59;
  font-weight: bold;
}

@media only screen and (max-width: 1024px) {
  #RankingMomentosBravecto .RankingMomentos-classificacao-container {
    overflow-x: scroll;
    padding: 15px;
  }

  #RankingMomentosBravecto .RankingMomentos-classificacao-container .Ranking-classificacao-sub-container {
    width: 1040px;
  }
}

@media only screen and (max-width: 768px) {
  #RankingMomentosBravecto .RankingMomentosBravecto-container-nf {
    height: 399px;
  }

  #RankingMomentosBravecto .RankingMomentos-sub-container #container__input input {
    width: 80%;
  }

  #RankingMomentosBravecto .RankingMomentosBravecto-sub-container-nf .total-nfs {
    align-items: center;
    flex-direction: column;
  }

  #RankingMomentosBravecto .RankingMomentosBravecto-sub-container-nf .total-nf {
    margin: 3.25rem 0;
  }

  #RankingMomentosBravecto .RankingMomentos-sub-container .RankingMomentos-inputSearch, #RankingMomentosBravecto .RankingMomentos-classificacao-container .RankingMomentos-inputSearch {
    flex-direction: column;
  }

  #RankingMomentosBravecto .RankingMomentos-sub-container #container__input, #RankingMomentosBravecto .RankingMomentos-classificacao-container #container__input {
    width: 100%;
    /* background-color: inherit; */
  }

  #RankingMomentosBravecto .RankingMomentos-classificacao-container .RankingMomentos-inputSearch .total-nf-cadastradas {
    margin: 1rem 0;
  }

  #RankingMomentosBravecto .RankingMomentos-sub-container .RankingMomentos-ganhadores-container {
    flex-wrap: wrap;
    justify-content: space-evenly;
  }

  #RankingMomentosBravecto .RankingMomentos-sub-container .RankingMomentos-ganhadores-container .primeiroLugar {
    order: 1 !important;
    width: 100%;
  }

  #RankingMomentosBravecto .RankingMomentos-sub-container .RankingMomentos-ganhadores-container .RankingMomentos-ganhadores {
    order: 2;
    margin: 0;
  }

  #RankingMomentosBravecto .RankingMomentos-sub-container .RankingMomentos-ganhadores-container .RankingMomentos-ganhadores.primeiroLugar::after {
    width: 172px;
    height: 150px;
  }

  #RankingMomentosBravecto .RankingMomentos-sub-container .RankingMomentos-ganhadores-container .RankingMomentos-ganhadores::after {
    width: 138px;
    height: 120px;
  }

  #RankingMomentosBravecto .RankingMomentos-sub-container .RankingMomentos-ganhadores-container .primeiroLugar p {
    font-size: 2.625rem !important;
  }

  #RankingMomentosBravecto .RankingMomentos-sub-container .RankingMomentos-ganhadores-container .RankingMomentos-ganhadores p {
    font-size: 2rem;
  }

  #RankingMomentosBravecto .RankingMomentos-sub-container .RankingMomentos-ganhadores-container .RankingMomentos-ganhadores span {
    font-size: 1.375rem;
  }

  #RankingMomentosBravecto .RankingMomentos-sub-container .RankingMomentos-ganhadores-container .primeiroLugar span {
    font-size: 1.813rem !important;
  }


  #RankingMomentosBravecto .RankingMomentos-sub-container .RankingMomentos-ganhadores-container .primeiroLugar .RankingMomentos-ganhadores-infos p {
    margin-top: 0;
  }

  #RankingMomentosBravecto .RankingMomentos-sub-container .RankingMomentos-ganhadores-container .RankingMomentos-ganhadores .RankingMomentos-ganhadores-infos p {
    margin: 0.5rem 0;
  }

  #RankingMomentosBravecto .RankingMomentos-sub-container .RankingMomentos-ganhadores-container .RankingMomentos-ganhadores .RankingMomentos-ganhadores-infos {
    margin-top: 1rem;
  }

  #RankingMomentosBravecto .RankingMomentos-sub-container .RankingMomentos-inputSearch,
  #RankingMomentosBravecto .Ranking-classificacao-sub-container .RankingMomentos-inputSearch {
    justify-content: center;
  }

  #RankingMomentosBravecto .RankingMomentos-sub-container .RankingMomentos-ganhadores-container .RankingMomentos-ganhadores
.RankingMomentos-ganhadores-infos .valor-premio {
  font-size: 1.125rem !important;
  width: 8.625rem;
}

#RankingMomentosBravecto .RankingMomentos-sub-container .RankingMomentos-ganhadores-container.primeiroLugar
.RankingMomentos-ganhadores-infos .valor-premio {
  font-size: 1.375rem !important;
  width: 10.75rem !important;
  height: 2.875rem !important;
}

#RankingMomentosBravecto .RankingMomentos-sub-container .RankingMomentos-ganhadores-container h2 {
  font-size: 1.25rem !important;
  top: -2.5rem !important;
}
#RankingMomentosBravecto .RankingMomentos-sub-container .RankingMomentos-ganhadores-container.primeiroLugar h2 {
  font-size: 1.5rem !important;
}
}

@media only screen and (max-width: 426px) {
  #RankingMomentosBravecto .RankingMomentos-sub-container h1 {
    font-size: 1.688rem;
  }
  #RankingMomentosBravecto .RankingMomentos-sub-container h2 {
    font-size: 1.375rem;
  }
}

@media only screen and (max-width: 320px) {
  #RankingMomentosBravecto .RankingMomentos-sub-container h1 {
    font-size: 1.4rem;
  }

  #RankingMomentosBravecto .RankingMomentos-sub-container .RankingMomentos-ganhadores-container .terceiroLugar {
    margin: 3rem 0;
  }
}