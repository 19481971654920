#Ivete {
  .banner {
    text-align: center;

    img {
      width: 100%;
      max-width: 60rem;
      display: block;
      margin: 0 auto;
    }

    .bnr-mob {
      display: none;
    }
  }

  .link {
    text-align: center;
    padding: 40px 0;

    .button {
      padding: 0.7rem 1.7rem;
      background-color: #cd1c28;
      color: white;
      text-decoration: none;
      border-radius: 30px 30px;
      margin: 2rem 0;
      font-size: 1.3em;
      font-weight: bold;
      font-family: sans-serif;
    }
  }
}

@media screen and (max-width: 760px) {
  #Ivete {
    position: relative;

    .banner {
      .bnr-desk {
        display: none;
      }

      .bnr-mob {
        display: block;
        width: 100%;
      }
    }

    .link {
      position: absolute;
      top: 40%;
      left: 0;
      right: 0;

      .button {
        font-size: 1rem;
      }
    }
  }
}
