#GarantiaBravecto {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .banner {
    text-align: center;
    margin-top: 2%;

    img {
      max-width: 100%;
      display: block;
      margin: 0 auto;
    }

    .bnr-mob {
      display: none;
    }
  }
}

.GarantiaBravecto__container {
  width: 70%;

  .page-title {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin: 2rem 0 2rem 0;

    span {
      background: linear-gradient(-101deg, #482774 20%, #e11832 100%);
      -webkit-background-clip: text;
      -webkit-text-fill-color: rgba(0, 0, 0, 0.0001);
      font-weight: 500;
      font-family: Helvetica, Arial;
      font-size: 1.25rem;
      letter-spacing: 0.03125rem;
    }
  }

  .rules__main {
    padding: 2rem 0;
    text-align: center;

    .rules {
      .title {
        display: flex;
        align-content: space-around;
        justify-content: center;
        align-items: center;
      }

      .hide-desktop {
        display: none;
      }

      h4 {
        color: #b42446;
        font-weight: 500;
        margin-top: 0;
      }

      h5 {
        color: #b42446;
        font-weight: 500;
        font-size: 1rem;
        text-align: left;
        padding-left: 2%;
      }

      p {
        color: #5f5f5f;
        font-weight: 500;

        > a {
          color: #b42446;
          text-decoration: none;

          &:hover {
            color: #6d031c !important;
          }
        }
      }

      span.steps {
        color: #b42446;
        font-weight: 500;
      }
    }
  }
}

@media screen and (max-width: 760px) {
  #GarantiaBravecto {
    .banner {
      .bnr-desk {
        display: none;
      }

      .bnr-mob {
        display: block;
      }
    }
  }

  .GarantiaBravecto__container {
    width: unset;
    padding: 0 1rem;

    .title {
      flex-direction: row-reverse;
      justify-content: flex-start;

      .steps {
        padding-right: 10%;
      }
    }
  }
}

@media screen and (min-width: 760px) {
  .hide-mobile {
    display: none;
  }

  .hide-desktop {
    display: initial !important;
  }
}
